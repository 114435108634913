import { differenceInCalendarDays } from "date-fns";
import { ja as localeJa } from "date-fns/locale";

import { formatStringDate, getCurrentDateTimeOnJst } from "~/libs/utils";

/**
 * 中継配送センターIDと荷物件数のリストを受け取り、表示用のデータに変換する
 * @param {Map<number, object>} centerMap
 * @param {Array<{transportDestivationId: number, packageNum: number}>} transportDestivationIdAndPackageNumList
 * @returns {string}
 */
export function getCenterNameAndPackageNumString(
  centerMap,
  transportDestivationIdAndPackageNumList,
) {
  let result = "";
  if (!centerMap || !transportDestivationIdAndPackageNumList) {
    return result;
  }
  for (const item of transportDestivationIdAndPackageNumList) {
    if (result !== "") {
      result += "<br />";
    }
    result += `${centerMap.get(item.transportDestivationId)?.name ?? "センター名取得失敗"} (${item.packageNum}個)`;
  }
  return result === "" ? "該当なし" : result;
}

/**
 * 中継配送センターIDのリストを受け取り、表示用のデータに変換する
 * @param {Map<number, object>} centerMap
 * @param {Array<number>} centerIdList
 * @returns {string}
 */
export function getCenterNameString(centerMap, centerIdList) {
  let result = "";
  if (!centerMap || !centerIdList) {
    return result;
  }
  for (const centerId of centerIdList) {
    if (result !== "") {
      result += "<br />";
    }
    result += centerMap.get(centerId)?.name ?? "センター名取得失敗";
  }
  return result === "" ? "該当なし" : result;
}

/**
 * 最終業務日時をもとに当日までの日数を返す。
 * 業務実績が無い場合はMAX_VALUEを返す。
 * @param {Array<{
 *   startAt: string,
 *   endAt: string,
 * }>} lastWorkedAt 最終業務日時
 * @returns {number} 当日までの日数
 */
export function getDaysFromLastWorkedAt(lastWorkedAt) {
  // 業務実績が無い場合はMAX_VALUEを返す
  if (!lastWorkedAt) {
    return Number.MAX_VALUE;
  }

  // 終了日が無い場合は開始日で判断
  const lastTimeset = lastWorkedAt[lastWorkedAt.length - 1];
  if (lastTimeset.endAt === undefined) {
    return differenceInCalendarDays(
      getCurrentDateTimeOnJst(),
      new Date(formatStringDate(lastTimeset.startAt, "yyyy/MM/dd HH:mm")),
    );
  }

  // 終了日と当日の日数差を返す
  return differenceInCalendarDays(
    getCurrentDateTimeOnJst(),
    new Date(formatStringDate(lastTimeset.endAt, "yyyy/MM/dd HH:mm")),
  );
}

/**
 * 業務実績の開始時間と終了時間を文字列にして返す。
 * @param {Array<{
 *   startAt: string,
 *   endAt: string,
 * }>} workTimes 最終業務日時
 * @returns {string} 業務時間の文字列
 */
export function getWorkedAtString(workTimes) {
  if (!workTimes) {
    return "";
  }
  return workTimes
    .map((timeset) => {
      return `<p style="margin: 1px 0;">${formatStringDate(timeset.startAt, "MM/dd(E) H:mm", { locale: localeJa })} ～ ${
        timeset.endAt
          ? formatStringDate(timeset.endAt, "MM/dd(E) H:mm", {
              locale: localeJa,
            })
          : getForgotStampLabel(workTimes)
      }`;
    })
    .join("<br />");
}

/**
 * 打刻忘れラベルを表示するかどうかを判定し、適切なHTMLを返す。
 * @param {Array<{
 *  startAt: string,
 *  endAt: string,
 * }>} workTimes 最終業務日時
 * @returns {string} 画面に表示するHTML
 */
function getForgotStampLabel(workTimes) {
  if (getDaysFromLastWorkedAt(workTimes) === 0) {
    return "</p>";
  } else {
    return '<span style="display: inline-block; vertical-align: middle; width: fit-content; font-size: 11px; color: white; background-color: #5f6368; margin-left: 5px; padding: 0 4px; border-radius: 4px;"><span style="display: flex; justify-content: center; align-items: center;"><span class="material-icons" style="font-size: 16px;">report</span>打刻忘れ</span></span></p>';
  }
}
