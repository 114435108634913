<script>
  import Button from "@smui/button";
  import Checkbox from "@smui/checkbox";
  import Dialog, { Actions, Content, Title } from "@smui/dialog";
  import FormField from "@smui/form-field";
  import { escape } from "html-escaper";
  import { HTTPError } from "ky";
  import { createEventDispatcher, getContext, onMount } from "svelte";
  import { _ } from "svelte-i18n";

  import backendApi from "~/libs/backendApi";
  import { HandledError } from "~/libs/commonTypes";
  import {
    CONTEXT_KEY_USER,
    CONTRACT_ROLES,
    EC_ROLES,
    PARTNER_ROLES,
    ROLES,
    ROLES_MAP,
    USER_KINDS_FOR_CONTRACT_ONLY,
    USER_KINDS_FOR_PARTNER,
  } from "~/libs/constants";
  import loadingProgress from "~/libs/loadingProgress";
  import { generatePassword } from "~/libs/passwordGenerator";
  import {
    editManagementResult,
    managementResultEditClose,
    needReload,
  } from "~/libs/stores";

  /** @type {import("~/libs/commonTypes").CustomedUserInfo} */
  export let result;

  /** @type {import("~/libs/backendApi").GetCompaniesResponse}>} */
  export let companyNameList;

  const UPDATE = false;
  const DISABLE = true;

  let open;
  let companyId;
  let displayUserKind;
  let displayNameChange = false;
  let userKindsChange = false;
  let userIdChange = false;
  let passwordChange = false;
  let emailAddressChange = false;
  let switchableEcChange = false;
  let contactInfoChange = false;
  let userKindsList = [];
  let changedUserKind = "";
  /** @type {boolean} */
  let changedAllowCoreDelivery = false;
  let changedUserName = "";
  let changedDisplayName = "";
  let changedPassword = "";
  let changedEmailAddress = "";
  let changedContactInfo = "";
  let dispatch = createEventDispatcher();
  let isDisable = result.disabled;

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /**
   * @typedef {import("~/libs/backendApi").Company&{selected?: boolean}} selectableCompany
   */
  /** 切り替え可能なECサイト @type {Array<selectableCompany>} */
  let options = (() => {
    /** @type {Array<selectableCompany>} */
    let options = [];
    /** 編集対象ユーザーの会社情報 @type {import("~/libs/backendApi").Company} */
    const ownCompanyInfo = companyNameList.find(
      (company) => company.id === result.companyId,
    );

    for (const company of ownCompanyInfo.switchableCompanies ?? []) {
      /** @type {selectableCompany} */
      const selectableCompany = company;
      if (result.switchableCompanyIds?.includes(selectableCompany.id)) {
        // 編集ユーザーの切替可能ECサイト設定に含まれる場合
        selectableCompany.selected = true;
      } else {
        // 編集ユーザーの切替可能ECサイト設定に含まれない場合
        selectableCompany.selected = false;
      }
      options.push(selectableCompany);
    }
    console.log("切替可能ECサイト", options);
    options = options.sort((a, b) => a.id - b.id);
    return options;
  })();

  $: updateButtonStates =
    (displayNameChange ||
      userKindsChange ||
      switchableEcChange ||
      userIdChange ||
      passwordChange ||
      emailAddressChange ||
      contactInfoChange) &&
    (!userKindsChange || (userKindsChange && changedUserKind)) &&
    (!userIdChange || (userIdChange && changedUserName)) &&
    (!displayNameChange || (displayNameChange && changedDisplayName)) &&
    (!passwordChange || (passwordChange && changedPassword)) &&
    (!emailAddressChange ||
      (emailAddressChange &&
        (changedEmailAddress === "" ||
          /^\S+@\S+$/.test(changedEmailAddress)))) &&
    (!contactInfoChange ||
      changedContactInfo === "" ||
      (contactInfoChange && changedContactInfo))
      ? false
      : true;

  onMount(openDialog);

  function openDialog() {
    companyId = result.companyId.toString().padStart(4, "0");
    changedUserKind = result.role;
    changedAllowCoreDelivery =
      result.switchableRoles === undefined
        ? false
        : result.switchableRoles.includes(ROLES.CONTRACT_DRIVER);
    changedUserName = result.userName.split(/^.*?\//)[1];
    changedDisplayName = result.displayName;
    changedEmailAddress = result.emailAddress ?? "";
    displayUserKind = ROLES_MAP.get(result.role);
    if (CONTRACT_ROLES.includes(result.role)) {
      userKindsList = USER_KINDS_FOR_CONTRACT_ONLY;
    } else if (PARTNER_ROLES.includes(result.role)) {
      userKindsList = USER_KINDS_FOR_PARTNER;
    }
    changedContactInfo = result.contactInfo ?? "";
    open = true;
  }

  function closeHandler(event) {
    if (event.detail.action == "cancel") {
      managementResultEditClose.set(true);
    }
  }

  function userKindsToggle() {
    userKindsChange = !userKindsChange;
  }

  function switchableEcToggle() {
    switchableEcChange = !switchableEcChange;
  }

  function userIdToggle() {
    userIdChange = !userIdChange;
  }

  function displayNameToggle() {
    displayNameChange = !displayNameChange;
  }

  function passwordToggle() {
    if (!passwordChange) {
      changedPassword = generatePassword();
    }
    passwordChange = !passwordChange;
  }

  function emailAddressToggle() {
    emailAddressChange = !emailAddressChange;
  }

  function contactInfoToggle() {
    contactInfoChange = !contactInfoChange;
  }

  const execUserUpdateApi = async (disable) => {
    /** ユーザー情報のうち初期化するプロパティ名の一覧 @type {Array<"switchableRoles" | "emailAddress" | "contactInfo">} */
    const initializeFields = [];

    /** @type {import("~/libs/backendApi").UpdateUserRequest} */
    let body = {
      userName: result.userName,
    };
    if (disable) {
      body.disabled = !isDisable;
    } else {
      if (userKindsChange) {
        body.role = changedUserKind;
        if (changedUserKind === "shipping-partner-driver") {
          if (changedAllowCoreDelivery) {
            body.switchableRoles = [ROLES.CONTRACT_DRIVER];
          } else {
            initializeFields.push("switchableRoles");
          }
        }
      }
      if (switchableEcChange) {
        body.switchableCompanyIds = options
          .filter((option) => option.selected)
          .map((option) => option.id);
      }
      if (userIdChange) {
        body.updatedUserName = companyId + "/" + changedUserName;
      }
      if (displayNameChange) {
        body.displayName = changedDisplayName;
      }
      if (passwordChange) {
        body.initialPassword = changedPassword;
      }
      if (emailAddressChange) {
        if (changedEmailAddress) {
          body.emailAddress = changedEmailAddress;
        } else {
          initializeFields.push("emailAddress");
        }
      }
      if (contactInfoChange) {
        if (changedContactInfo) {
          body.contactInfo = changedContactInfo;
        } else {
          initializeFields.push("contactInfo");
        }
      }
    }
    if (initializeFields.length > 0) {
      body.initializeFields = initializeFields;
    }

    await backendApi.updateUser(body);
  };

  const update = loadingProgress.wrapAsync(async () => {
    /** @type {import("~/libs/backendApi").UserInfo} */
    let newResult;
    try {
      await execUserUpdateApi(UPDATE);
      newResult = Object.assign({}, result);
      if (switchableEcChange) {
        newResult.switchableCompanyIds = options
          .filter((option) => option.selected)
          .map((option) => option.id);
      }
      if (userIdChange) {
        newResult.userName = companyId + "/" + changedUserName;
      }
      if (displayNameChange) {
        newResult.displayName = changedDisplayName;
      }
      if (emailAddressChange) {
        if (changedEmailAddress) {
          newResult.emailAddress = changedEmailAddress;
        } else {
          newResult.emailAddress = "";
        }
      }
      if (contactInfoChange) {
        newResult.contactInfo = changedContactInfo;
      }
      dispatch("message", {
        result: "success",
        title: $_("message.updateComplete"),
        message: "",
      });
    } catch (error) {
      /** @type {import("~/libs/backendApi").ErrorResponse} */
      const errorResponse = error["errorResponse"];

      if (
        errorResponse?.title == "invalidParam" &&
        errorResponse?.details?.violations
      ) {
        /** @type {Array<{level: string, path: string, message: string}>} */
        const violations = errorResponse.details.violations;
        const outputViolations = violations
          .map((v) => "・" + $_("classes.user." + v.path) + "：" + v.message)
          .join("<br />");

        showErrorMessage(
          new HandledError(
            $_("errors.userUpdateError.title"),
            $_("errors.userUpdateError.message", {
              values: { violations: outputViolations },
            }),
          ),
        );
      } else if (
        errorResponse?.title == "userNameAlreadyExist" &&
        errorResponse?.details?.violations
      ) {
        /** @type {{path: string, param: string}} */
        const violations = errorResponse.details.violations;
        const outputViolations =
          $_("classes.user." + violations.path) + "：" + violations.param;
        showErrorMessage(
          new HandledError(
            $_("errors.userUpdateExistUserNameError.title"),
            $_("errors.userUpdateExistUserNameError.message", {
              values: { violations: outputViolations },
            }),
          ),
        );
      } else if (
        errorResponse?.title == "emailAddressAlreadyExist" &&
        errorResponse?.details?.violations
      ) {
        /** @type {{path: string, param: string}} */
        const violations = errorResponse.details.violations;
        const outputViolations =
          $_("classes.user." + violations.path) + "：" + violations.param;
        showErrorMessage(
          new HandledError(
            $_("errors.userUpdateExistEmailAddressError.title"),
            $_("errors.userUpdateExistEmailAddressError.message", {
              values: { violations: outputViolations },
            }),
          ),
        );
      } else {
        showErrorMessage(error);
      }
      newResult = null;
    }
    editManagementResult.set(newResult);
    managementResultEditClose.set(true);
    needReload.set(true);
  });

  const disable = loadingProgress.wrapAsync(async () => {
    /** @type {import("~/libs/backendApi").UserInfo} */
    let newResult;
    try {
      await execUserUpdateApi(DISABLE);
      newResult = Object.assign({}, result);
      newResult.disabled = !isDisable;
      if (isDisable) {
        dispatch("message", {
          result: "success",
          title: $_("message.updateComplete"),
          message: "ユーザー有効化の反映に5分程度かかる場合があります。",
        });
      } else {
        dispatch("message", {
          result: "success",
          title: $_("message.updateComplete"),
          message: "",
        });
      }
    } catch (error) {
      /** @type {import("~/libs/backendApi").ErrorResponse} */
      const errorResponse = error["errorResponse"];

      if (
        errorResponse?.title == "invalidParam" &&
        errorResponse?.details?.violations
      ) {
        /** @type {Array<{level: string, path: string, message: string}>} */
        const violations = errorResponse.details.violations;
        const outputViolations = violations
          .map((v) => "・" + $_("classes.user." + v.path) + "：" + v.message)
          .join("<br />");

        showErrorMessage(
          new HandledError(
            $_("errors.userUpdateError.title"),
            $_("errors.userUpdateError.message", {
              values: { violations: outputViolations },
            }),
          ),
        );
      } else if (
        errorResponse?.title == "userNameAlreadyExist" &&
        errorResponse?.details?.violations
      ) {
        /** @type {{path: string, param: string}} */
        const violations = errorResponse.details.violations;
        const outputViolations =
          $_("classes.user." + violations.path) + "：" + violations.param;
        showErrorMessage(
          new HandledError(
            $_("errors.userUpdateExistUserNameError.title"),
            $_("errors.userUpdateExistUserNameError.message", {
              values: { violations: outputViolations },
            }),
          ),
        );
      } else if (
        errorResponse?.title == "emailAddressAlreadyExist" &&
        errorResponse?.details?.violations
      ) {
        /** @type {{path: string, param: string}} */
        const violations = errorResponse.details.violations;
        const outputViolations =
          $_("classes.user." + violations.path) + "：" + violations.param;
        showErrorMessage(
          new HandledError(
            $_("errors.userUpdateExistEmailAddressError.title"),
            $_("errors.userUpdateExistEmailAddressError.message", {
              values: { violations: outputViolations },
            }),
          ),
        );
      } else {
        showErrorMessage(error);
      }
    }
    editManagementResult.set(newResult);
    managementResultEditClose.set(true);
    needReload.set(true);
  });

  /**
   * エラーメッセージをダイアログで表示する。
   * @param {Error} error Errorオブジェクト
   */
  function showErrorMessage(error) {
    if (error instanceof HandledError) {
      dispatch("message", {
        result: "failed",
        title: error.title,
        message: error.message,
      });
    } else {
      if (error instanceof HTTPError && error.response?.status == 401) {
        dispatch("message", {
          result: "failed",
          title: $_("errors.unauthorized.title"),
          message: $_("errors.unauthorized.message"),
        });
      } else if (error instanceof HTTPError && error.response?.status == 403) {
        dispatch("message", {
          result: "failed",
          title: $_("errors.forbidden.title"),
          message: $_("errors.forbidden.message"),
        });
      } else {
        console.error(error);
        dispatch("message", {
          result: "failed",
          title: $_("errors.defaultMessage.title"),
          message: $_("errors.defaultMessage.message"),
        });
      }
    }
  }

  /**
   * 切り替え可能なECサイトとして表示する文字列を返す。
   * @returns {string} 切り替え可能なECサイトとして表示する文字列
   */
  function getDisplaySwitchableEcName() {
    let switchableEcName = "";
    options.forEach((option) => {
      if (option.id !== result.companyId && option.selected) {
        if (switchableEcName) {
          switchableEcName += "／";
        }
        switchableEcName += option.name;
      }
    });
    if (switchableEcName === "") {
      switchableEcName = "未設定";
    }
    return switchableEcName;
  }
</script>

<div class="dialog">
  <Dialog
    bind:open
    aria-labelledby="detail-dialog-title"
    aria-describedby="detail-dialog-content"
    on:SMUIDialog:closed={closeHandler}
    style="margin-top: 30px; max-height: 95%; z-index: 100;"
  >
    <Title id="detail-dialog-title">
      ユーザー情報の変更
      {#if !userContext.hasPermitUserManagementRole() || result.userName != userContext.loginUser.username}
        {#if isDisable}
          <Button
            style="width: 216px; background-color: #F90404; color: #fff; margin-left: auto;"
            on:click={disable}>このユーザーを有効にする</Button
          >
        {:else}
          <Button
            style="width: 216px; background-color: #F90404; color: #fff; margin-left: auto;"
            on:click={disable}>このユーザーを無効にする</Button
          >
        {/if}
      {/if}
    </Title>

    <Content id="detail-dialog-content">
      <div class="item">
        <div class="itemName company">会社名</div>
        <div class="itemLine">
          <p>{result.companyName}</p>
        </div>
      </div>
      <div class="item">
        <div class="itemName">ユーザー種別</div>
        {#if userKindsChange === false}
          <div class="userKindsWrapper">
            <div class="itemLine">
              <p>{displayUserKind}</p>
            </div>
            {#if changedUserKind === ROLES.SHIPPING_PARTNER_DRIVER || changedUserKind === ROLES.SHIPPING_PARTNER_ADMIN}
              {#if result.switchableRoles?.includes(ROLES.CONTRACT_DRIVER)}
                <p class="switchableText">※幹線輸送可能</p>
              {:else}
                <p class="switchableText">※幹線輸送不可</p>
              {/if}
            {/if}
          </div>
        {:else}
          <div class="editableUserKindsWrapper">
            <div class="itemLine">
              <select
                name="userKindsChange"
                id="userKindsChange"
                bind:value={changedUserKind}
              >
                <option value="" selected disabled>選択してください</option>
                {#each userKindsList as { value, kind }}
                  <option {value}>{kind}</option>
                {/each}
              </select>
            </div>
            {#if changedUserKind === ROLES.SHIPPING_PARTNER_DRIVER}
              <FormField>
                <Checkbox bind:checked={changedAllowCoreDelivery} />
                <span slot="label">幹線輸送を行うことを可能とする</span>
              </FormField>
            {:else if changedUserKind === ROLES.SHIPPING_PARTNER_ADMIN}
              <FormField>
                <Checkbox checked disabled />
                <span slot="label" style="color:#666;"
                  >幹線輸送を行うことを可能とする</span
                >
              </FormField>
            {/if}
          </div>
        {/if}
        {#if !EC_ROLES.includes(result.role)}
          <Button
            variant="unelevated"
            on:click={userKindsToggle}
            style="margin: auto 0;">{userKindsChange ? "戻す" : "変更"}</Button
          >
        {:else}
          <div style="width: 66px;" />
        {/if}
      </div>
      {#if changedUserKind === "ec-admin" && options.length > 0}
        <div class="item">
          <div class="itemName">切替可能な<br />ECサイト</div>
          <div class="itemLine">
            {#if switchableEcChange === false}
              <div class="switchableEcName">
                <p>{getDisplaySwitchableEcName()}</p>
              </div>
            {:else}
              <div class="itemBlock">
                {#each options as option}
                  {#if option.id !== result.companyId}
                    <FormField style="margin-right: 10px;">
                      <Checkbox bind:checked={option.selected} />
                      <span slot="label">{option.name}</span>
                    </FormField>
                  {/if}
                {/each}
              </div>
            {/if}
            <Button
              variant="unelevated"
              on:click={switchableEcToggle}
              style="margin: auto 0;"
              >{switchableEcChange ? "戻す" : "変更"}</Button
            >
          </div>
        </div>
      {/if}
      <div class="item">
        <div class="itemName">ユーザーID</div>
        <div class="itemLine">
          {#if userIdChange === false}
            <p>{result.userName}</p>
          {:else}
            <div class="itemFlex">
              <div id="inputNumber">
                <span>{companyId}</span>
              </div>
              <input type="text" id="inputOther" bind:value={changedUserName} />
            </div>
          {/if}
          <Button
            variant="unelevated"
            on:click={userIdToggle}
            style="margin: auto 0;">{userIdChange ? "戻す" : "変更"}</Button
          >
        </div>
      </div>
      <div class="item">
        <div class="itemName">表示名</div>
        <div class="itemLine">
          {#if displayNameChange === false}
            <p>{result.displayName}</p>
          {:else}
            <textarea
              name="displayName"
              id="displayName"
              bind:value={changedDisplayName}
              style="width: 244px; height: 40px;"
            ></textarea>
          {/if}
          <Button
            variant="unelevated"
            on:click={displayNameToggle}
            style="margin: auto 0;"
            >{displayNameChange ? "戻す" : "変更"}</Button
          >
        </div>
      </div>
      <div class="item">
        <div class="itemName">
          <p>メールアドレス<span class="optionLabel">(任意)</span></p>
        </div>
        <div class="itemLine">
          {#if emailAddressChange === false}
            <p>{result.emailAddress ? result.emailAddress : ""}</p>
          {:else}
            <input
              type="text"
              style="width: 240px;"
              bind:value={changedEmailAddress}
            />
          {/if}
          <Button
            variant="unelevated"
            on:click={emailAddressToggle}
            style="margin: auto 0;"
            >{emailAddressChange ? "戻す" : "変更"}</Button
          >
        </div>
      </div>
      <div class="item">
        <div class="itemName">パスワード</div>
        <div class="itemLine">
          <div>
            {#if result.initialPasswordChangeRequired && passwordChange === false}
              <p class="passwordText">未設定</p>
            {:else if !result.initialPasswordChangeRequired && passwordChange === false}
              <p class="passwordText">設定済</p>
            {:else}
              <input
                type="text"
                style="width: 240px;"
                bind:value={changedPassword}
              />
            {/if}
            <p class="subText">
              変更ボタンを押すと仮パスワードが発行されます。
            </p>
          </div>
          <Button
            variant="unelevated"
            on:click={passwordToggle}
            style="margin: auto 0;">{passwordChange ? "戻す" : "変更"}</Button
          >
        </div>
      </div>
      <div class="item">
        <div class="itemName">
          <p>管理者専用メモ<span class="optionLabel">(任意)</span></p>
        </div>
        <div class="itemLine">
          <div>
            {#if contactInfoChange === false}
              {#if result.contactInfo}
                <p class="commentBox">
                  {@html escape(result.contactInfo).replace(/\n/g, "<br />")}
                </p>
              {:else}
                <p class="commentBox">未登録</p>
              {/if}
            {:else}
              <textarea
                name="contactInfo"
                id="contactInfo"
                bind:value={changedContactInfo}
                style="width: 242px; height: 80px;"
              ></textarea>
            {/if}
            <p class="subText">
              所属や連絡先などの情報保持にご利用いただけます。
            </p>
          </div>
          <Button
            variant="unelevated"
            on:click={contactInfoToggle}
            style="margin: auto 0;"
            >{contactInfoChange ? "戻す" : "変更"}</Button
          >
        </div>
      </div>
      <div class="alertMessage">
        <span class="material-icons .md-18"> warning_amber </span>
        <p>
          設定したパスワードはお手元に必ずお控えの上、<br />
          当該ユーザーへ正しくお伝えください。
        </p>
      </div>
    </Content>
    <Actions>
      <Button style="background-color: #D9D9D9; color: #333;" action={"cancel"}
        >閉じる</Button
      >
      <Button
        variant="unelevated"
        on:click={update}
        bind:disabled={updateButtonStates}
        action={"update"}>保存</Button
      >
    </Actions>
  </Dialog>
</div>

<style lang="scss">
  .dialog {
    :global(.mdc-dialog__title) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 13px;
      padding-bottom: 12px;
    }
  }
  .item {
    display: flex;
    position: relative;
    width: 500px;
    margin: 6px auto;
    padding-bottom: 6px;
    border-bottom: 1px solid #eee;
  }
  .itemName {
    background-color: #b4d0f1cb;
    width: 150px;
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    color: #242424;
    font-size: smaller;
    font-weight: 900;
    text-align: center;
    line-height: 1.2;
  }
  .company {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .itemLine {
    width: 350px;
    height: auto;
    display: flex;
    justify-content: space-between;
    line-height: normal;
  }
  .itemLine > p,
  .passwordText {
    color: #333;
    padding-top: 8px;
    font-size: 13px;
  }
  .itemBlock {
    width: 278px;
    display: block;
  }
  .userKindsWrapper {
    width: 278px;
  }
  .switchableText {
    color: #666;
    font-size: 12px;
  }
  .editableUserKindsWrapper {
    width: 278px;
    padding: 0;
  }
  #userKindsChange {
    width: 248px;
    height: 34px;
  }
  .switchableEcName {
    display: flex;
    align-items: center;
    color: #333;
  }
  .itemFlex {
    display: flex;
    justify-content: start;
  }
  #inputNumber {
    width: 60px;
    font-size: 13px;
    background-color: #ddd;
    border: 1px solid #777;
    border-radius: 3px;
    text-align: center;
    padding-top: 10px;
  }
  #inputNumber span {
    color: #333;
  }
  #inputOther {
    width: 180px;
  }
  .optionLabel {
    font-size: 10px;
  }
  .subText {
    margin-top: 6px;
    padding-left: 1em;
    text-indent: -1em;
    line-height: 1.2em;
    font-size: 11px;
    color: #666;
  }
  .commentBox {
    width: 238px;
    padding: 4px;
    font-size: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    line-height: 1.2em;
    overflow-wrap: break-word;
  }
  .alertMessage {
    margin: 8px 0;
    font-size: 13px;
    line-height: 1.4;
    padding: 6px 0;
    color: #672b2a;
    background-color: #ffe7e7;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    .material-icons {
      color: #d74141;
    }
  }
</style>
