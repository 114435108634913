<script>
  import { createEventDispatcher } from "svelte";
  import { _ } from "svelte-i18n";

  import { STATUS_OUT_FOR_DELIVERY } from "~/libs/constants";
  import CorrectedReceiverAddress from "~/pages/Search/Update/EditableFields/CorrectedReceiverAddress.svelte";

  /** @type {import("~/libs/commonTypes").DetailedShipment} */
  export let shipment;

  let dispatch = createEventDispatcher();

  /**
   * 入力内容が変更されたとき、SearchResultUpdatePattern.svelteにイベントを発行する。
   * @param {CustomEvent<{isAllConditionsMet: boolean, updateShipmentEvent: import("~/libs/backendApi").UpdateShipmentEvent}>} event
   */
  function onInputChange(event) {
    /** @type {boolean} 登録に必要な条件が全てそろっているか判定するフラグ */
    let isAllConditionsMet = false;
    isAllConditionsMet = event.detail.isAllConditionsMet;

    // 更新する荷物情報を作成
    /** @type {import("~/libs/backendApi").UpdateShipmentEvent} 更新する荷物情報 */
    const updateShipmentEvent = {
      trackingNumber: shipment.trackingNumber,
      status: shipment.status,
      version: shipment.version,
    };
    updateShipmentEvent.correctedReceiverAddress =
      event.detail.updateShipmentEvent.correctedReceiverAddress;

    if (shipment.status === STATUS_OUT_FOR_DELIVERY && shipment.driverId) {
      // 持出中の場合はドライバーに通知する
      const message = {
        title: $_(
          "pages.Search.pushNotificationMessage.title.correctedReceiverAddress",
        ),
        body: $_("pages.Search.pushNotificationMessage.body", {
          values: { trackingNumber: shipment.trackingNumber },
        }),
      };
      updateShipmentEvent.pushNotification = {
        title: message.title,
        body: message.body,
        data: {
          message: message,
          trackingNumber: shipment.trackingNumber,
          correctedReceiverAddress:
            event.detail.updateShipmentEvent.correctedReceiverAddress,
        },
      };
    }

    // イベントを発行
    dispatch("inputChange", {
      isAllConditionsMet,
      updateShipmentEvent,
    });
  }
</script>

<div class="wrapper">
  <CorrectedReceiverAddress on:inputChange={onInputChange} />
</div>
