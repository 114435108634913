<script>
  import { _ } from "svelte-i18n";

  import { shippingAndReturnStatusType } from "~/libs/constants";

  export let status;
  export let hasShippingPartnerAdminRole;

  let displayStatus;
  let displayReturnReason;
  let displayPreviousStatus;
  (() => {
    if (status.substring(0, 1) === "2") {
      const returnStatusType = status.substring(0, 2);
      const returnReason = status.substring(2, 3);
      switch (returnStatusType) {
        case shippingAndReturnStatusType.RETURN_REQUESTING:
          displayStatus = hasShippingPartnerAdminRole
            ? $_("classes.returnStatusForDeliveryPartner.0")
            : $_("classes.returnStatus.0");
          displayPreviousStatus = $_(`classes.status.${status.substring(3)}`);
          break;
        case shippingAndReturnStatusType.RETURN_WAITING:
          displayStatus = $_("classes.returnStatus.1");
          break;
        case shippingAndReturnStatusType.RETURNING:
          displayStatus = $_("classes.returnStatus.2");
          break;
        case shippingAndReturnStatusType.RETURN_COMPLETE:
          displayStatus = $_("classes.returnStatus.3");
          break;
      }
      displayReturnReason = $_(`classes.returnReason.${returnReason}`);
    } else {
      const shortendedStatus = status.substring(0, 3);
      switch (shortendedStatus) {
        case shippingAndReturnStatusType.CREATED:
          displayStatus = $_("classes.status.0");
          break;
        case shippingAndReturnStatusType.IN_TRANSIT:
          displayStatus = $_("classes.status.1");
          break;
        case shippingAndReturnStatusType.HELD_IN_DEPOT:
          displayStatus = $_("classes.status.2");
          break;
        case shippingAndReturnStatusType.OUT_FOR_DELIVERY:
          displayStatus = $_("classes.status.3");
          break;
        case shippingAndReturnStatusType.DELIVERED:
          displayStatus = $_("classes.status.4");
          break;
        case shippingAndReturnStatusType.CANCELLED:
          displayStatus = $_("classes.shipmentCanceled");
          break;
        case shippingAndReturnStatusType.RETURN_REQUESTING:
          displayStatus = hasShippingPartnerAdminRole
            ? $_("classes.returnStatusForDeliveryPartner.0")
            : $_("classes.returnStatus.0");
          displayReturnReason = $_(
            `classes.returnReason.${status.substring(2, 3)}`,
          );
          break;
        case shippingAndReturnStatusType.RETURN_WAITING:
          displayStatus = $_("classes.returnStatus.1");
          displayReturnReason = $_(
            `classes.returnReason.${status.substring(2, 3)}`,
          );
          break;
        case shippingAndReturnStatusType.RETURNING:
          displayStatus = $_("classes.returnStatus.2");
          displayReturnReason = $_(
            `classes.returnReason.${status.substring(2, 3)}`,
          );
          break;
        case shippingAndReturnStatusType.RETURN_COMPLETE:
          displayStatus = $_("classes.returnStatus.3");
          displayReturnReason = $_(
            `classes.returnReason.${status.substring(2, 3)}`,
          );
          break;
        case shippingAndReturnStatusType.UNDELIVERABLE_WITH_LOST:
          displayStatus = $_("classes.lost");
          break;
        case shippingAndReturnStatusType.UNDELIVERABLE_WITH_MISDELIVERY:
          displayStatus = $_("classes.misdeliveryState");
          break;
        case shippingAndReturnStatusType.UNDELIVERABLE_WITH_NOT_ACTUALLY_RECEIVED:
          displayStatus = $_("classes.notActuallyReceived");
          displayPreviousStatus = $_(`classes.status.${status.substring(3)}`);
      }
    }
  })();
</script>

{displayStatus}
{#if displayPreviousStatus}
  <span class="smallText">&nbsp;※現在{displayPreviousStatus}</span>
{/if}
{#if displayReturnReason}
  <p class="smallText">
    ({displayReturnReason})
  </p>
{/if}

<style>
  .smallText {
    font-size: 12px;
  }
</style>
