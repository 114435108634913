<script>
  import Button from "@smui/button";
  import Dialog, { Actions, Content, InitialFocus, Title } from "@smui/dialog";
  import FormField from "@smui/form-field";
  import Switch from "@smui/switch";
  import { ja as localeJa } from "date-fns/locale";
  import { escape } from "html-escaper";
  import { HTTPError } from "ky";
  import { afterUpdate, getContext } from "svelte";
  import { _ } from "svelte-i18n";

  import OverlappingPhotoDialog from "~/components/OverlappingPhotoDialog.svelte";
  import backendApi from "~/libs/backendApi";
  import {
    CONTEXT_KEY_USER,
    RETURN_STATUS_REQUESTING,
    STATUS_CREATED,
  } from "~/libs/constants";
  import desiredDateTime from "~/libs/desiredDateTime";
  import loadingProgress from "~/libs/loadingProgress";
  import {
    searchResultDialogClose,
    searchResultUpdateOpen,
  } from "~/libs/stores";
  import { toast } from "~/libs/toast";
  import {
    formatStringDate,
    formatTrackingNumber,
    formatUtcToJst,
  } from "~/libs/utils";
  import SearchResultDialogTable from "~/pages/Search/SearchResultDialogTable.svelte";

  /** @type {import("~/libs/backendApi").SearchedShipment} */
  export let result;
  /** @type {string} */
  export let dialogMessage;

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  let open;

  let photoDialogComponent;
  /** @type {string} */
  let dialogTitle;
  /** @type {string} */
  let photoUrl;
  /** @type {string} 置き配写真のURL*/
  let unattendedDeliveryPhotoUrl;
  /**
   * 置き配写真を表示するボタン要素を取得する。
   */
  let unattendedDeliveryPhotoButton;
  /**
   * 配達票写真を表示するボタン要素を取得する。
   */
  let signaturePhotoButton;

  /**
   * @typedef {{
   *   name: string,
   *   info: string | number | boolean,
   *   html?: boolean,
   * }} TableItem
   *
   * @typedef {{
   *   base: Array<TableItem>,
   *   receiver: Array<TableItem>,
   *   shipper: Array<TableItem>,
   *   request: Array<TableItem>,
   *   record: Array<TableItem>,
   *   return: Array<TableItem>,
   *   other: Array<TableItem>,
   * }} TableItems
   */

  /** 詳細情報 */
  let deliveryDetails;

  /** @type {import("~/libs/commonTypes").DetailedShipment} */
  let maskedDetailedShipment;
  /** @type {import("~/libs/commonTypes").DetailedShipment} */
  let unmaskedDetailedShipment;

  loadingProgress.wrapAsync(async () => {
    await loadMaskedDetailedShipment();
  })();

  /**
   * マスクされた詳細情報を取得する。
   */
  async function loadMaskedDetailedShipment() {
    /** @type {import("~/libs/commonTypes").DetailedShipment} */
    maskedDetailedShipment = await backendApi.getDetailedShipment(
      result.trackingNumber,
      true,
    );
    deliveryDetails = createTableItems(maskedDetailedShipment);
  }

  /**
   * @param {import("~/libs/commonTypes").DetailedShipment} detailed
   * @returns {TableItems}
   */
  function createTableItems(detailed) {
    /**
     * 詳細情報をフォーマットして表示。
     * @type {object}
     */
    const formattedDetails = {
      /**
       * EC事業者名
       * @type {string}
       */
      customerName:
        detailed.customerId !== undefined
          ? `${detailed.customerName ?? ""}(ID:${detailed.customerId})`
          : null,
      /**
       * お届け先の郵便番号
       * @type {string}
       */
      receiverPostcode:
        detailed.receiverPostcode !== undefined
          ? `${displayPostcodeFormat(detailed.receiverPostcode)}`
          : null,
      /**
       * ご依頼主の郵便番号
       * @type {string}
       */
      shipperPostcode:
        detailed.shipperPostcode !== undefined
          ? `${displayPostcodeFormat(detailed.shipperPostcode)}`
          : null,
      /**
       * 宅配ドライバー名
       * @type {string}
       */
      driverName:
        detailed.driverId !== undefined
          ? `${detailed.driverDisplayName ?? ""}` +
            `${detailed.driverUserName ? "(ID:" + detailed.driverUserName + ")" : ""}`
          : null,
      /**
       * 送り状発行時の日時と場所
       * @type {string}
       */
      created: detailed.createdAt
        ? `${formatStringDate(detailed.createdAt, "yyyy/MM/dd HH:mm")}`
        : "",
      /**
       * 荷受け時の日時と場所
       * @type {string}
       */
      inTransit: detailed.inTransitAt
        ? `${formatStringDate(detailed.inTransitAt, "yyyy/MM/dd HH:mm")} ${
            detailed.inTransitLocationName ?? ""
          }`
        : "",
      /**
       * 持ち出し時の日時と場所
       * @type {string}
       */
      outForDelivery: detailed.outForDeliveryAt
        ? `${formatStringDate(detailed.outForDeliveryAt, "yyyy/MM/dd HH:mm")} ${
            detailed.outForDeliveryLocationName ?? ""
          }`
        : "",
      /**
       * 保管時の日時と場所
       * @type {string}
       */
      heldInDepot: detailed.heldInDepotAt
        ? `${formatStringDate(detailed.heldInDepotAt, "yyyy/MM/dd HH:mm")} ${
            detailed.heldInDepotLocationName ?? ""
          }`
        : "",
      /**
       * 返品待ち登録時の日時と場所
       * @type {string}
       */
      waitingForReturn: detailed.waitingForReturnAt
        ? `${formatStringDate(detailed.waitingForReturnAt, "yyyy/MM/dd HH:mm")} ${
            detailed.waitingForReturnLocationName ?? ""
          }`
        : "",
    };

    /**
     * 配送／返品ステータスをHTML形式に成形する。
     * @type {string}
     */
    const status = (() => {
      if (detailed.lost) {
        return $_(`classes.lost`);
      } else if (detailed.misdeliveryState === 0) {
        return $_(`classes.misdeliveryState`);
      } else if (detailed.notActuallyReceived) {
        return (
          $_(`classes.notActuallyReceived`) +
          (detailed.status != null
            ? `&nbsp;※現在${$_(`classes.status.${detailed.status}`)}`
            : "")
        );
      } else if (detailed.returnStatus == null) {
        return detailed.status != null
          ? $_(`classes.status.${detailed.status}`)
          : "";
      } else {
        if (detailed.returnStatus != null) {
          if (detailed.status === STATUS_CREATED) {
            return $_(`classes.shipmentCanceled`);
          } else if (detailed.returnStatus === RETURN_STATUS_REQUESTING) {
            if (userContext.hasShippingPartnerAdminRole()) {
              return (
                $_(
                  `classes.returnStatusForDeliveryPartner.${detailed.returnStatus}`,
                ) +
                (detailed.status != null
                  ? `&nbsp;※現在${$_(`classes.status.${detailed.status}`)}`
                  : "") +
                (detailed.returnReason != null
                  ? `<p>(${$_(`classes.returnReason.${detailed.returnReason}`)})</p>`
                  : "")
              );
            } else {
              return (
                $_(`classes.returnStatus.${detailed.returnStatus}`) +
                (detailed.status != null
                  ? `&nbsp;※現在${$_(`classes.status.${detailed.status}`)}`
                  : "") +
                (detailed.returnReason != null
                  ? `<p>(${$_(`classes.returnReason.${detailed.returnReason}`)})</p>`
                  : "")
              );
            }
          } else {
            return (
              $_(`classes.returnStatus.${detailed.returnStatus}`) +
              (detailed.returnReason != null
                ? `<p>(${$_(`classes.returnReason.${detailed.returnReason}`)})</p>`
                : "")
            );
          }
        } else {
          return "";
        }
      }
    })();

    /**
     * 配達希望日時をHTML形式に成形する。
     * @type {string}
     */
    const dateAndTime = (() => {
      let dateAndTime = "";
      /** @type {Array<import("~/libs/commonTypes").DateAndTimeFrame>}*/
      const dateAndTimeList = desiredDateTime.resolve(detailed);

      if (dateAndTimeList.length > 1) {
        // 配達希望日時が複数指定されている場合
        for (const dateAndTimeFrame of dateAndTimeList) {
          dateAndTime += `${formatStringDate(
            dateAndTimeFrame.date,
            "yyyy/M/d(E)",
            {
              locale: localeJa,
            },
          )} ${
            dateAndTimeFrame.timeFrame ===
            desiredDateTime.UNRECEIVABLE_TIME_SLOT
              ? "受け取り不可"
              : `${`${dateAndTimeFrame.timeFrame.substring(0, 2).replace(/^0/, "")}`}${`${dateAndTimeFrame.timeFrame.substring(2).replace(/^0/, "")}` != "0" ? `～${dateAndTimeFrame.timeFrame.substring(2).replace(/^0/, "")}時` : "時～"}`
          }</p>`;
        }
        return dateAndTime;
      } else if (dateAndTimeList.length == 1) {
        // 配達希望日時が１つだけ指定されている場合
        dateAndTime += `${
          dateAndTimeList[0].date
            ? formatStringDate(dateAndTimeList[0].date, "yyyy/M/d(E)", {
                locale: localeJa,
              })
            : "日付指定なし"
        }
        &nbsp;${
          dateAndTimeList[0].timeFrame
            ? `${`${dateAndTimeList[0].timeFrame.substring(0, 2).replace(/^0/, "")}`}${`${dateAndTimeList[0].timeFrame.substring(2).replace(/^0/, "")}` != "0" ? `～${dateAndTimeList[0].timeFrame.substring(2).replace(/^0/, "")}時` : "時～"}`
            : "時間指定なし"
        }`;
        return dateAndTime;
      } else {
        // 配達希望日時が指定されていない場合
        if (detailed.redeliveryContext?.redeliveryDatetimeSpecMethod == null) {
          return "日時指定なし";
        } else {
          return "設定依頼中";
        }
      }
    })();

    /**
     * 配送中の発生事象をHTML形式に成形する。
     * @type {string}
     */
    const troubles = (() => {
      let troubles = "";
      let extraEvents = detailed.extraEvent;
      if (extraEvents) {
        troubles = extraEvents
          .map(
            (extraEvent) =>
              `<strong>` +
              $_(
                `pages.Search.extraEventTypeLabel.${extraEvent.extraEventType}`,
              ) +
              "</strong>" +
              ` - ${escape(formatStringDate(extraEvent.time, "yyyy/MM/dd HH:mm"))}` +
              "<div style='margin-bottom: 10px; word-wrap: break-word; white-space: pre-wrap;'><p style='width: 370px; border: 1px solid #ddd; border-radius: 4px; font-size: 12px; padding: 4px;'>" +
              $_(`message.trouble.${extraEvent.extraEventType}`) +
              "</p></div>",
          )
          .join("");
      }
      return troubles;
    })();

    /** @type {TableItems} */
    const tableItems = {
      base: [
        {
          name: "送り状番号",
          info: formatTrackingNumber(detailed.trackingNumber),
        },
        {
          name: "配送／返品ステータス",
          info: status,
          html: true,
        },
        { name: "EC事業者名", info: formattedDetails.customerName },
        { name: "注文番号", info: detailed.customerOrderId },
        {
          name: "発行日時",
          info: detailed.releasedAt
            ? formatUtcToJst(detailed.releasedAt, "yyyy/MM/dd H:mm", {
                locale: localeJa,
              })
            : "",
        },
        {
          name: "個数",
          info:
            (detailed.numberOfPackages ? detailed.numberOfPackages : 1) + "個",
        },
        {
          name: "貨物サイズ",
          info: detailed.cubicSize
            ? detailed.cubicSize +
              (detailed.numberOfPackages > 1 ? "（全量をまとめたサイズ）" : "")
            : "未登録",
        },
        {
          name: "支払い方法",
          info:
            detailed.cashOnDeliveryAmount != null
              ? "代引き（" +
                detailed.cashOnDeliveryAmount.toLocaleString() +
                "円）"
              : "元払い",
        },
        {
          name: "配達証明の種別",
          info: detailed.signatureRequired
            ? "受領印もしくは署名"
            : "手渡し：なし、置き配：写真",
        },
      ],
      receiver: [
        { name: "電話番号", info: detailed.receiverTel },
        { name: "郵便番号", info: formattedDetails.receiverPostcode },
        { name: "住所1", info: detailed.receiverAddress1 },
        { name: "住所2", info: detailed.receiverAddress2 },
        {
          name: "住所 (訂正後)",
          info: detailed.correctedReceiverAddress,
        },
        { name: "お届け先名", info: detailed.receiverName },
        {
          name: "メールアドレス",
          info: detailed.receiverEmailAddress,
        },
      ],
      shipper: [
        { name: "電話番号", info: detailed.shipperTel },
        { name: "郵便番号", info: formattedDetails.shipperPostcode },
        { name: "住所1", info: detailed.shipperAddress1 },
        { name: "住所2", info: detailed.shipperAddress2 ?? "" },
        { name: "ご依頼主名", info: detailed.shipperName },
        { name: "備考1", info: detailed.shipperRemarks1 },
        { name: "備考2", info: detailed.shipperRemarks2 },
      ],
      request: [
        {
          name:
            detailed.numberOfDeliveryAttempts >= 1
              ? "再配達希望日時"
              : "配達希望日時",
          info: dateAndTime,
          html: true,
        },
        {
          name: "受け渡し方法 (希望)",
          info:
            detailed.packageDropPlace != null
              ? $_(`classes.packageDropPlace.${detailed.packageDropPlace}`)
              : "",
        },
      ],
      record: [
        {
          name: "受け渡し方法 (実績)",
          info:
            detailed.actualPackageDropPlace != null
              ? $_(
                  `classes.packageDropPlace.${detailed.actualPackageDropPlace}`,
                )
              : "",
        },
        { name: "宅配パートナー名", info: detailed.delivererName },
        {
          name: "宅配ドライバー名",
          info: formattedDetails.driverName,
        },
        { name: "送り状発行", info: formattedDetails.created },
        { name: "荷受け", info: formattedDetails.inTransit },
        { name: "保管", info: formattedDetails.heldInDepot },
        { name: "持ち出し", info: formattedDetails.outForDelivery },
        {
          name: "配達完了",
          info: detailed.deliveredAt
            ? formatStringDate(detailed.deliveredAt, "yyyy/MM/dd HH:mm")
            : "",
        },
        {
          name: "紛失",
          info: detailed.lostAt
            ? formatStringDate(detailed.lostAt, "yyyy/MM/dd HH:mm")
            : "",
        },
        {
          name: "誤送",
          info: detailed.misdeliveredAt
            ? formatStringDate(detailed.misdeliveredAt, "yyyy/MM/dd HH:mm")
            : "",
        },
        {
          name: "置き配写真",
          info:
            detailed.unattendedDeliveryPhotoUrl != null
              ? `<button id="unattendedDeliveryPhoto" class="mdc-button mdc-ripple-upgraded" fill="true" style="--mdc-ripple-fg-size: 38px; --mdc-ripple-fg-scale: 2.1955326053353494; --mdc-ripple-fg-translate-start: 30.845947265625px, 1.09173583984375px; --mdc-ripple-fg-translate-end: 13px, -1px; margin-left: -16px;"><div class="mdc-button__ripple"></div>表示</button>`
              : "未登録",
          html: true,
        },
        {
          name: "配達票写真",
          info: detailed.signaturePhotoUploaded
            ? `<button id="signaturePhoto" class="mdc-button mdc-ripple-upgraded" fill="true" style="--mdc-ripple-fg-size: 38px; --mdc-ripple-fg-scale: 2.1955326053353494; --mdc-ripple-fg-translate-start: 30.845947265625px, 1.09173583984375px; --mdc-ripple-fg-translate-end: 13px, -1px; margin-left: -16px;"><div class="mdc-button__ripple"></div>表示</button>`
            : "未登録",
          html: true,
        },
        { name: "宅配ボックス番号", info: detailed.deliveryBoxNumber },
        { name: "宅配ボックス暗証番号", info: detailed.deliveryBoxPin },
        {
          name: "輸送中の外装汚損の発生有無",
          info: detailed.damaged ? "有り" : "無し",
        },
        { name: "配送中の発生事象", info: troubles, html: true },
        {
          name: "配達試行回数",
          info: detailed.numberOfDeliveryAttempts,
        },
      ],
      return: [
        {
          name: userContext.hasShippingPartnerAdminRole()
            ? $_(`classes.returnStatusForDeliveryPartner.0`)
            : $_(`classes.returnStatus.0`),
          info: detailed.requestingForReturnAt
            ? formatStringDate(
                detailed.requestingForReturnAt,
                "yyyy/MM/dd HH:mm",
              )
            : "",
        },
        {
          name: $_(`classes.returnStatus.1`),
          info: formattedDetails.waitingForReturn,
        },
        {
          name: $_(`classes.returnStatus.2`),
          info: detailed.returningAt
            ? formatStringDate(detailed.returningAt, "yyyy/MM/dd HH:mm")
            : "",
        },
        {
          name: $_(`classes.returnStatus.3`),
          info: detailed.returnedAt
            ? formatStringDate(detailed.returnedAt, "yyyy/MM/dd HH:mm")
            : "",
        },
      ],
      other: [
        {
          name: "盗難保険サービス利用",
          info: detailed.supportTheftInsurance ? "⭕" : "❌",
        },
        {
          name: "オートロック付きマンション置き配サービス利用",
          info: detailed.supportAutoLockUnlocking ? "⭕" : "❌",
        },
        {
          name: `通信欄${!userContext.hasEcAdminRole() ? " (対EC事業者)" : ""}`,
          info: detailed.ecDelivererInternalMessage
            ? `<div class="communicationArea">
                ${escape(detailed.ecDelivererInternalMessage).replace(
                  /\n/g,
                  "<br />",
                )}
               </div>
               <p class="note">※お客様へは公開されない、${
                 userContext.hasEcAdminRole() ? "配送事業者" : "EC事業者"
               }との情報共有スペース</p>`
            : "",
          html: true,
        },
        {
          name: "通信欄 (配送事業者内)",
          info: detailed.delivererInternalMessage
            ? `<div class="communicationArea">
                ${escape(detailed.delivererInternalMessage).replace(
                  /\n/g,
                  "<br />",
                )}
               </div>
               <p class="note">※お客様とEC事業者へは公開されない、配送事業者内(幹線輸送事業者と宅配事業者)の情報共有スペース</p>`
            : "",
          html: true,
        },
        {
          name: "宅配事業者管理者専用メモ欄",
          info: detailed.shippingPartnerInternalMessage
            ? `<div class="communicationArea">
                ${escape(detailed.shippingPartnerInternalMessage).replace(/\n/g, "<br />")}
               </div>
               <p class="note">※宅配事業者の管理者専用の内部メモ (管理業務の状況整理用)</p>`
            : `<div class="communicationArea">&nbsp;</div>
               <p class="note">※宅配事業者の管理者専用の内部メモ (管理業務の状況整理用)</p>`,
          html: true,
        },
      ],
    };

    // ECサイトのユーザーには一部項目を表示しない。
    if (userContext.hasEcAdminRole()) {
      tableItems.record = tableItems.record.filter(
        (obj) =>
          obj.name !== "宅配パートナー名" && obj.name !== "宅配ドライバー名",
      );
      tableItems.other = tableItems.other.filter(
        (obj) => obj.name !== "通信欄 (配送事業者内)",
      );

      // 代引をサポートしていない場合のみ一部項目を表示しない。
      if (!userContext.ecSettings.supportCashOnDelivery) {
        tableItems.other = tableItems.other.filter(
          (obj) => obj.name !== "代引金額",
        );
      }
    }

    // 宅配パートナー管理者以外のユーザーには一部項目を表示しない。
    if (!userContext.hasShippingPartnerAdminRole()) {
      tableItems.other = tableItems.other.filter(
        (obj) => obj.name !== "宅配事業者管理者専用メモ欄",
      );
    }

    // 宅配ボックスを使用しないユーザーには一部項目を表示しない。
    if (!detailed.deliveryBoxNumber) {
      tableItems.record = tableItems.record.filter(
        (obj) => obj.name !== "宅配ボックス番号",
      );
    }
    if (!detailed.deliveryBoxPin) {
      tableItems.record = tableItems.record.filter(
        (obj) => obj.name !== "宅配ボックス暗証番号",
      );
    }

    // 配達証明の種別によって表示する写真を変更する。
    if (detailed.signatureRequired) {
      // 受領印もしくは署名が必要な場合は、置き配写真を表示しない。
      tableItems.record = tableItems.record.filter(
        (obj) => obj.name !== "置き配写真",
      );
    } else {
      // 手渡し：なし、置き配：写真の場合は、置き配写真・配達票写真のうち写真が登録されている方を出す。
      // どちらも登録されていない場合は、どちらも表示する。
      if (
        detailed.unattendedDeliveryPhotoUrl ||
        detailed.signaturePhotoUploaded
      ) {
        if (detailed.unattendedDeliveryPhotoUrl) {
          tableItems.record = tableItems.record.filter(
            (obj) => obj.name !== "配達票写真",
          );
        } else {
          tableItems.record = tableItems.record.filter(
            (obj) => obj.name !== "置き配写真",
          );
        }
      }
    }

    // 置き配写真のURLをダイアログで表示できるようにする。
    unattendedDeliveryPhotoUrl = detailed.unattendedDeliveryPhotoUrl;

    return tableItems;
  }

  /**
   * 郵便番号のフォーマット変換を行う。
   * @param {string} postcode
   * @returns {string}
   */
  function displayPostcodeFormat(postcode) {
    let startCode = postcode.slice(0, 3);
    let endCode = postcode.slice(3);
    let formattedPostcode = startCode + "-" + endCode;
    return formattedPostcode;
  }

  /**
   * 写真ダイアログを表示する。
   * @param {string} title
   * @param {string} url
   */
  function displayPhotoDialog(title, url) {
    dialogTitle = title;
    photoUrl = url;
    photoDialogComponent = OverlappingPhotoDialog;
  }

  /**
   * 置き配写真のダイアログを表示する。
   */
  function openUnattendedDeliveryPhotoDialog() {
    displayPhotoDialog("置き配写真", unattendedDeliveryPhotoUrl);
  }

  /**
   * 配達票写真のダイアログを表示する。
   */
  async function opensignaturePhotoDialog() {
    try {
      /** @type {import("~/libs/backendApi.js").signaturePhotoUrlResponse} */
      const signaturePhotoUrlResponse = await backendApi.getSignaturePhotoUrl(
        result.trackingNumber,
      );
      displayPhotoDialog("配達票写真", signaturePhotoUrlResponse.url);
    } catch (error) {
      showErrorToast(error);
    }
  }

  afterUpdate(() => {
    unattendedDeliveryPhotoButton = document.getElementById(
      "unattendedDeliveryPhoto",
    );
    signaturePhotoButton = document.getElementById("signaturePhoto");

    // 置き配写真/配達票写真を表示するボタン要素にクリックイベントを設定する。
    if (unattendedDeliveryPhotoButton) {
      // 置き配写真の項目が表示されている場合
      unattendedDeliveryPhotoButton.addEventListener(
        "click",
        openUnattendedDeliveryPhotoDialog,
      );
    }
    if (signaturePhotoButton) {
      // 配達票写真の項目が表示されている場合
      signaturePhotoButton.addEventListener("click", opensignaturePhotoDialog);
    }
  });

  /**
   * 詳細画面を閉じる。
   */
  function closeHandler() {
    searchResultDialogClose.set(true);
  }

  /**
   * 編集画面を開く。
   */
  function openWindow() {
    searchResultUpdateOpen.set(true);
  }

  /** ダイアログコンポーネントを削除する */
  function dialogCloseHandler() {
    photoDialogComponent = null;
  }

  /**
   * エラーメッセージをトーストで表示する。
   * @param {Error} error Errorオブジェクト
   */
  function showErrorToast(error) {
    if (error instanceof HTTPError && error.response?.status == 401) {
      toast.error($_("errors.updateUnauthorized.message"));
    } else if (error instanceof HTTPError && error.response?.status == 403) {
      toast.error($_("errors.updateForbidden.message"));
    } else {
      console.error(error);
      toast.error($_("errors.updateDefaultMessage.message"));
    }
  }

  open = true;
</script>

<svelte:component
  this={photoDialogComponent}
  {dialogTitle}
  {photoUrl}
  {dialogCloseHandler}
/>
{#if deliveryDetails}
  <div class="dialogWrapper">
    <Dialog
      bind:open
      aria-labelledby="detail-dialog-title"
      aria-describedby="detail-dialog-content"
      on:SMUIDialog:closed={closeHandler}
      style="margin-top: 30px; max-height: 90%; z-index: 100;"
    >
      <Title id="detail-dialog-title">詳細情報</Title>
      <Content id="detail-dialog-content">
        {#if dialogMessage}
          <p class="message">{dialogMessage}</p>
        {/if}
        <SearchResultDialogTable
          groupingTitle={"基本情報"}
          groupingDetails={deliveryDetails.base}
        />
        <SearchResultDialogTable
          groupingTitle={"お届け先"}
          groupingDetails={deliveryDetails.receiver}
        />
        <SearchResultDialogTable
          groupingTitle={"ご依頼主"}
          groupingDetails={deliveryDetails.shipper}
        />
        <SearchResultDialogTable
          groupingTitle={"配達希望"}
          groupingDetails={deliveryDetails.request}
        />
        <SearchResultDialogTable
          groupingTitle={"配達実績"}
          groupingDetails={deliveryDetails.record}
        />
        <SearchResultDialogTable
          groupingTitle={"返品実績"}
          groupingDetails={deliveryDetails.return}
        />
        <SearchResultDialogTable
          groupingTitle={"その他"}
          groupingDetails={deliveryDetails.other}
        />
      </Content>
      <div class="buttonArea">
        {#if !userContext.hasSccOperatorRole()}
          <FormField style="margin-right: auto;">
            <Switch
              checked={false}
              on:SMUISwitch:change={async (
                /** @type {CustomEvent} */ event,
              ) => {
                if (event.detail.selected) {
                  if (!unmaskedDetailedShipment) {
                    unmaskedDetailedShipment =
                      await backendApi.getDetailedShipment(
                        result.trackingNumber,
                        false,
                      );
                  }
                  deliveryDetails = createTableItems(unmaskedDetailedShipment);
                } else {
                  deliveryDetails = createTableItems(maskedDetailedShipment);
                }
              }}
            />
            <span slot="label">マスクされている個人情報の表示</span>
          </FormField>
        {/if}
        <Actions>
          <Button style="background-color: #D9D9D9; color: #333;">閉じる</Button
          >
          {#if !userContext.hasSccOperatorRole()}
            <Button
              touch
              variant="unelevated"
              use={[InitialFocus]}
              on:click={openWindow}>編集</Button
            >
          {/if}
        </Actions>
      </div>
    </Dialog>
  </div>
{/if}

<style lang="scss">
  .message {
    color: black;
    font-weight: bold;
    margin: 5px;
  }

  .buttonArea {
    display: flex;
    justify-content: end;

    :global(.mdc-dialog__actions) {
      border-top: none;
    }
  }

  .dialogWrapper {
    :global(.mdc-dialog .mdc-dialog__container) {
      width: 90%;
    }
    :global(.mdc-dialog .mdc-dialog__surface) {
      width: 100%;
      min-width: 300px;
      max-width: 700px;
    }
    :global(.mdc-dialog__title) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 13px;
      padding-bottom: 12px;
    }
    :global(.mdc-dialog__title .mdc-button) {
      background-color: #f90404;
      color: #fff;
      margin-left: auto;
      width: 100px;
    }
  }
</style>
