/* Context API用のキー */
export const CONTEXT_KEY_APP = "Symbol(1)";
export const CONTEXT_KEY_USER = "Symbol(2)";

/** @enum {string} ユーザーロールの一覧 */
export const ROLES = Object.freeze({
  /** 管理事業者／管理者 */
  CONTRACT_ADMIN: "contract-admin",
  /** 管理事業者／幹線輸送ドライバー */
  CONTRACT_DRIVER: "contract-driver",
  /** 宅配事業者／管理者 */
  SHIPPING_PARTNER_ADMIN: "shipping-partner-admin",
  /** 宅配事業者／ドライバー */
  SHIPPING_PARTNER_DRIVER: "shipping-partner-driver",
  /** EC事業者／管理者 */
  EC_ADMIN: "ec-admin",
  /** SCC／運用担当者 */
  SCC_OPERATOR: "scc-operator",
});

/** @type {Array<string>} 管理機能へのログインが許可されたユーザーロールの一覧 */
// @ts-ignore
export const PERMIT_LOGIN_ROLES = Object.freeze([
  ROLES.CONTRACT_ADMIN,
  ROLES.SHIPPING_PARTNER_ADMIN,
  ROLES.EC_ADMIN,
  ROLES.SCC_OPERATOR,
]);

// ユーザー権限
export const ROLES_MAP = new Map([
  ["contract-admin", "管理事業者／管理者"],
  ["contract-driver", "管理事業者／幹線輸送ドライバー"],
  ["shipping-partner-admin", "宅配事業者／管理者"],
  ["shipping-partner-driver", "宅配事業者／ドライバー"],
  ["ec-admin", "EC事業者／管理者"],
  ["scc-operator", "SCC／運用担当者"],
]);
export const CONTRACT_ROLES = ["contract-admin", "contract-driver"];
export const PARTNER_ROLES = [
  "shipping-partner-admin",
  "shipping-partner-driver",
];
export const EC_ROLES = ["ec-admin"];
export const SCC_ROLES = ["scc-operator"];
export const PERMIT_USER_MANAGEMENT_ROLES = [
  "contract-admin",
  "shipping-partner-admin",
  "ec-admin",
  "scc-operator",
];

// ユーザー種別
export const USER_KINDS_FOR_CONTRACT = [
  { value: "contract-admin", kind: "管理事業者／管理者" },
  { value: "contract-driver", kind: "管理事業者／幹線輸送ドライバー" },
  { value: "shipping-partner-admin", kind: "宅配事業者／管理者" },
  { value: "shipping-partner-driver", kind: "宅配事業者／ドライバー" },
  { value: "ec-admin", kind: "EC事業者／管理者" },
];
export const USER_KINDS_FOR_CONTRACT_ONLY = [
  { value: "contract-admin", kind: "管理事業者／管理者" },
  { value: "contract-driver", kind: "管理事業者／幹線輸送ドライバー" },
];
export const USER_KINDS_FOR_PARTNER = [
  { value: "shipping-partner-admin", kind: "宅配事業者／管理者" },
  { value: "shipping-partner-driver", kind: "宅配事業者／ドライバー" },
];
export const USER_KINDS_FOR_EC = [
  { value: "ec-admin", kind: "EC事業者／管理者" },
];

// 配送ステータス
export const STATUS_CREATED = 0;
export const STATUS_IN_TRANSIT = 1;
export const STATUS_HELD_IN_DEPOT = 2;
export const STATUS_OUT_FOR_DELIVERY = 3;
export const STATUS_DELIVERED = 4;
export const STATUS_LIST = [
  STATUS_CREATED,
  STATUS_IN_TRANSIT,
  STATUS_HELD_IN_DEPOT,
  STATUS_OUT_FOR_DELIVERY,
  STATUS_DELIVERED,
];

// 置き配場所
export const DROPPLACE_FRONT_OF_DOOR = 0;
export const DROPPLACE_DELIVERY_BOX = 1;
export const DROPPLACE_METER_BOX = 2;
export const DROPPLACE_MAILBOX = 3;
export const DROPPLACE_FRONT_OF_ENTRANCE = 4;
export const DROPPLACE_IN_PERSON = 5;
export const DROPPLACE_LIST = [
  DROPPLACE_FRONT_OF_DOOR,
  DROPPLACE_DELIVERY_BOX,
  DROPPLACE_METER_BOX,
  DROPPLACE_MAILBOX,
  DROPPLACE_IN_PERSON,
];
/** @type {Array<0 | 1 | 2 | 5>} */
export const EC_SELECTABLE_DROPPLACE_LIST = [
  DROPPLACE_FRONT_OF_DOOR,
  DROPPLACE_DELIVERY_BOX,
  DROPPLACE_METER_BOX,
  DROPPLACE_IN_PERSON,
];

// トラブル
export const TROUBLE_NON_ADDRESS = 0;
export const TROUBLE_ADDRESS_UNKNOWN = 1;
export const TROUBLE_LOSS = 2;
export const UNABLE_TO_DELIVER_TODAY = 3;
export const ABSENCE = 4;
export const ACCEPT_DENIED = 5;
export const DELIVERY_TIMES_LIMIT_OVER = 6;
export const TROUBLE_LIST = [
  TROUBLE_NON_ADDRESS,
  TROUBLE_ADDRESS_UNKNOWN,
  TROUBLE_LOSS,
  UNABLE_TO_DELIVER_TODAY,
  ABSENCE,
  ACCEPT_DENIED,
  DELIVERY_TIMES_LIMIT_OVER,
];

// 返品ステータス
export const RETURN_STATUS_REQUESTING = 0;
export const RETURN_STATUS_WAITING = 1;
export const RETURN_STATUS_RETURNING = 2;
export const RETURN_STATUS_COMPLETE = 3;
export const RETURN_STATUS_LIST = [
  RETURN_STATUS_REQUESTING,
  RETURN_STATUS_WAITING,
  RETURN_STATUS_RETURNING,
  RETURN_STATUS_COMPLETE,
];

/**
 * 返品理由の区分
 * @enum {0 | 1 | 2 | 3 | 4 | 5 | 6}
 */
export const ReturnReason = Object.freeze({
  /** EC事業者から返品要求 */
  REQUESTED_RETURN_FROM_EC: 0,
  /** 荷受人から注文キャンセル要求 */
  REQUESTED_CANCEL_FROM_RECEIVER: 1,
  /** 受け取り拒否 */
  ACCEPT_DENIED: 2,
  /** 宛先住所間違い */
  ADDRESS_WRONG: 3,
  /** 宛先住所不明 */
  ADDRESS_UNKNOWN: 4,
  /** 再配達回数上限超過 */
  REDELIVERY_LIMIT_EXCEEDED: 5,
  /** 荷物に問題が発生した */
  SHIPMENT_PROBLEM: 6,
});

/**
 * 回収結果の区分
 * @enum {0 | 1 | 2}
 */
export const RecoveryResult = Object.freeze({
  /** 荷物を回収できなかった */
  FAILURE: 0,
  /** 荷物を回収できた（未開封かつ損壊なし） */
  SUCCESS_WITHOUT_DAMAGE: 1,
  /** 荷物を回収できた（開封済または損壊あり） */
  SUCCESS_WITH_DAMAGE: 2,
});

// トースト表示時間
export const TOAST_DURATION = 5000;

/**
 * パスワード強度
 * @enum {0 | 1 | 2}
 */
export const PasswordStrength = Object.freeze({
  WEAK: 0,
  MEDIUM: 1,
  STRONG: 2,
});

/**
 * 出荷データ取込タイプ
 * @enum {0 | 1}
 */
export const uploadType = Object.freeze({
  TEMPORARY: 0,
  CONFIRMED: 1,
});

/**
 * 配送情報検索タイプ
 * @enum {0 | 1 | 2 | 3 | 4}
 */
export const searchConditionType = Object.freeze({
  ALL: 0,
  CENTER: 1,
  EC: 2,
  SUMMARY: 3,
  REPORT: 4,
});

/**
 * 検索結果一覧表示制御用配送・返品ステータス区分
 * @enum {string}
 */
export const shippingAndReturnStatusType = Object.freeze({
  CREATED: "000",
  IN_TRANSIT: "010",
  HELD_IN_DEPOT: "020",
  OUT_FOR_DELIVERY: "030",
  DELIVERED: "040",
  CANCELLED: "100",
  RETURN_REQUESTING: "21",
  RETURN_WAITING: "22",
  RETURNING: "23",
  RETURN_COMPLETE: "24",
  UNDELIVERABLE_WITH_LOST: "900",
  UNDELIVERABLE_WITH_MISDELIVERY: "910",
  UNDELIVERABLE_WITH_NOT_ACTUALLY_RECEIVED: "920",
});

/** 再配達の時間帯作成で用いる選択肢 */
export const CHOICES_OF_TIME = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
];

/**
 * 配送拠点の種別 (ビットフラグ)
 * @typedef {1 | 2 | 4} DepotType
 * @enum {DepotType}
 */
export const DepotTypes = Object.freeze({
  EC: 1 << 0,
  CONTRACT: 1 << 1,
  TRUMP: 1 << 2,
});

/**
 * ドライバー稼働状況一覧表示タイプ
 * @enum {0 | 1}
 */
export const driverConditionType = Object.freeze({
  /** 幹線輸送ドライバー */
  TRUCKER: 0,
  /** 宅配ドライバー */
  COURIER: 1,
});

/**
 * 配送記録のレコードタイプ
 * @enum {0 | 1 | 2 | 3 | 4 | 5 | 6}
 */
export const DeliveryRecordTypes = Object.freeze({
  /** 持出し */
  OUT_FOR_DELIVERY: 0,
  /** 配達移動中 */
  DRIVING: 1,
  /** 配達完了 */
  DELIVERED: 2,
  /** 配達不可 */
  UNDELIVERABLE: 3,
  /** 全て配達完了 */
  ALL_DELIVERED: 4,
  /** 全て持ち戻り完了 */
  ALL_TAKEBACK: 5,
  /** 配達不可(移動無し) */
  UNDELIVERABLE_NO_MOVE: 6,
});

/**
 * 配送情報の編集パターン
 * @enum {0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13}
 */
export const EditPattern = Object.freeze({
  /** 配達キャンセル登録 */
  CANCEL_DELIVERY: 0,
  /** 紛失登録 */
  LOST: 1,
  /** 紛失登録解除 */
  LOST_CANCEL: 2,
  /** 誤送登録 */
  MISDELIVERY: 3,
  /** 誤送登録解除 */
  MISDELIVERY_CANCEL: 4,
  /** 返品登録 */
  RETURN: 5,
  /** 未荷受け登録 */
  UNRECEIVED: 6,
  /** 未荷受け登録を解除 */
  UNRECEIVED_CANCEL: 7,
  /** 再配達依頼の登録 */
  REDELIVERY: 8,
  /** 訂正住所の登録 */
  CORRECT_ADDRESS: 9,
  /** 置き配写真の登録 */
  DELIVERY_PHOTO: 10,
  /** 配送中の発生事象の登録 */
  TROUBLES: 11,
  /** 配送ステータスの修正 */
  DELIVERY_STATUS: 12,
  /** その他 */
  OTHER: 13,
});
