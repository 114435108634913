<script>
  import Checkbox from "@smui/checkbox";
  import FormField from "@smui/form-field";
  import Radio from "@smui/radio";
  import Select, { Option } from "@smui/select";
  import { createEventDispatcher, getContext } from "svelte";
  import { _ } from "svelte-i18n";

  import {
    CONTEXT_KEY_USER,
    RETURN_STATUS_COMPLETE,
    RETURN_STATUS_LIST,
    RETURN_STATUS_REQUESTING,
    RETURN_STATUS_RETURNING,
    RETURN_STATUS_WAITING,
    ReturnReason,
    STATUS_HELD_IN_DEPOT,
    STATUS_OUT_FOR_DELIVERY,
  } from "~/libs/constants";
  import depotLocations from "~/libs/depotLocations";

  /** @type {import("~/libs/commonTypes").DetailedShipment} */
  export let shipment;

  let dispatch = createEventDispatcher();

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** @type {0 | 1 | 2 | 3}*/
  let changedReturnStatus;
  let changedReturnReason;
  let returnStatusList = [];
  let returnReasonList = [
    ReturnReason.REQUESTED_RETURN_FROM_EC,
    ReturnReason.REQUESTED_CANCEL_FROM_RECEIVER,
    ReturnReason.ACCEPT_DENIED,
    ReturnReason.ADDRESS_WRONG,
    ReturnReason.ADDRESS_UNKNOWN,
    ReturnReason.REDELIVERY_LIMIT_EXCEEDED,
    ReturnReason.SHIPMENT_PROBLEM,
  ];

  /**
   * 返品理由の選択肢に表示する再配達試行回数
   * @type {number}
   */
  let displayNumberOfDeliveryAttempts;

  /**
   * 返品ステータス・理由登録のバリデーションエラーを表示するかどうかを示すフラグ
   * @type {boolean}
   */
  let displayReturnStatusError = false;

  /**
   * 「返品ステータス登録を行う」にチェックが入っているかどうかを保持する変数
   * @type {boolean}
   */
  let returnRegisterChecked = true;

  /**
   * 「返品ステータス解除を行う」にチェックが入っているかどうかを保持する変数
   * @type {boolean}
   */
  let returnCancelChecked = false;

  /**
   * @typedef {import("~/libs/commonTypes").DepotLocation & {centers: Array<{distance: string, isNeary: boolean}>}} ExtraDepotLocation
   */
  /** @type {Array<ExtraDepotLocation>} */
  let locationList = [];
  /**  @type {number} */
  let selectedLocation;

  // ページの初期化処理（非同期）
  (async () => {
    changedReturnStatus = shipment.returnStatus;
    changedReturnReason = shipment.returnReason;
    returnStatusList = RETURN_STATUS_LIST;

    // 作業場所（配送センター）の取得
    locationList = /** @type {Array<ExtraDepotLocation>} */ (
      await depotLocations.get()
    );
  })();

  /**
   * 入力内容が変更されたとき、SearchResultUpdatePattern.svelteにイベントを発行する。
   */
  function onInputChange() {
    /** @type {boolean} 登録に必要な条件が全てそろっているか判定するフラグ */
    let isAllConditionsMet = false;

    if (
      changedReturnStatus === RETURN_STATUS_WAITING ||
      changedReturnStatus === RETURN_STATUS_RETURNING
    ) {
      // 返品ステータスが「返品待ち」もしくは「返品中」の場合
      if (
        returnReasonList.includes(changedReturnReason) &&
        Number.isInteger(selectedLocation)
      ) {
        // 返品理由と配送センターが選択されていれば、登録に必要な条件が全てそろっていると判定
        isAllConditionsMet = true;
      } else {
        isAllConditionsMet = false;
      }
    } else if (
      changedReturnStatus === RETURN_STATUS_REQUESTING ||
      changedReturnStatus === RETURN_STATUS_COMPLETE
    ) {
      // 返品ステータスが「返品要求」もしくは「返品完了」の場合
      if (returnReasonList.includes(changedReturnReason)) {
        // 返品理由が選択されていれば、登録に必要な条件が全てそろっていると判定
        isAllConditionsMet = true;
      } else {
        isAllConditionsMet = false;
      }
    }
    // 登録に必要な条件が全てそろっていない場合、エラーメッセージを表示する
    displayErrorMessage(!isAllConditionsMet);

    // 更新する荷物情報を作成
    /** @type {import("~/libs/backendApi").UpdateShipmentEvent} 更新する荷物情報 */
    const updateShipmentEvent = {
      trackingNumber: shipment.trackingNumber,
      status: shipment.status,
      version: shipment.version,
    };
    updateShipmentEvent.returnStatus = changedReturnStatus;
    updateShipmentEvent.returnReason = changedReturnReason;

    if (
      changedReturnStatus !== RETURN_STATUS_REQUESTING &&
      shipment.status === STATUS_OUT_FOR_DELIVERY
    ) {
      // 返品ステータスが「返品要求」以外、かつ配送ステータスが「持出中」の場合は配送ステータスを「保管中」に変更
      updateShipmentEvent.status = STATUS_HELD_IN_DEPOT;
    }
    if (
      changedReturnStatus === RETURN_STATUS_WAITING ||
      changedReturnStatus === RETURN_STATUS_RETURNING
    ) {
      // 返品ステータスが「返品待ち」もしくは「返品中」の場合は配送センターをセット
      updateShipmentEvent.locationId = selectedLocation;
    }

    // イベントを発行
    dispatch("inputChange", {
      isAllConditionsMet,
      updateShipmentEvent,
    });
  }

  /**
   * エラーメッセージの表示／非表示を切り替える
   * @param {boolean} isDisplay
   */
  function displayErrorMessage(isDisplay) {
    displayReturnStatusError = isDisplay;
  }
</script>

<div class="wrapper">
  <div class="editableItemArea">
    <div class="item" id="status">
      <div class="itemTh">配送／返品ステータス</div>
      <div class="itemTd">
        <div class="itemTdLeft column">
          <Select
            style="width: 484px;"
            variant="outlined"
            id="statusChange"
            value={shipment.status}
            disabled
          >
            <Option value={shipment.status}
              >{$_(`classes.status.${shipment.status}`)}</Option
            >
          </Select>

          {#if !Number.isInteger(shipment?.returnStatus)}
            <!-- IF 返品ステータスが未登録の場合 -->
            <FormField>
              <Checkbox bind:checked={returnRegisterChecked} disabled />
              <span slot="label">返品ステータスを登録する</span>
            </FormField>
          {/if}

          {#if (Number.isInteger(shipment?.returnStatus) && !returnCancelChecked) || (!Number.isInteger(shipment?.returnStatus) && returnRegisterChecked)}
            <!--
                  IF 返品ステータスが登録済かつ「返品ステータスの登録を解除する」をチェックしていない、
                    もしくは返品ステータスが未登録かつ「返品ステータスを登録する」をチェックしている場合
                  -->
            <div class="returnRegisterArea">
              <p class="title">【返品に関する登録情報】</p>

              {#if userContext.hasContractAdminRole() || userContext.hasShippingPartnerAdminRole()}
                {#each returnStatusList as returnStatus}
                  <FormField>
                    <Radio
                      bind:group={changedReturnStatus}
                      value={returnStatus}
                      on:change={onInputChange}
                    />
                    <span slot="label">
                      {#if userContext.hasContractAdminRole() || userContext.hasTrumpAdminRole()}
                        {$_(`classes.returnStatus.${returnStatus}`)}
                      {:else}
                        {$_(
                          `classes.returnStatusForDeliveryPartner.${returnStatus}`,
                        )}
                      {/if}
                    </span>
                  </FormField>
                {/each}
              {/if}

              <Select
                style="width: 485px; margin-top: 15px; background-color: #fff;"
                variant="outlined"
                bind:value={changedReturnReason}
                on:SMUISelect:change={onInputChange}
                required
                list$dense
                list$twoLine
              >
                <Option value="" style="height: 50px;"
                  >返品理由を選択してください</Option
                >
                {#each returnReasonList as returnReason}
                  <Option value={returnReason} style="height: 50px;">
                    <p class="returnReasonItem">
                      {$_(`classes.returnReason.${returnReason}`)}
                      {#if returnReason == ReturnReason.REDELIVERY_LIMIT_EXCEEDED}
                        <strong
                          >(現在{displayNumberOfDeliveryAttempts ??
                            "0"}回)</strong
                        >
                      {/if}
                      <span class="example">
                        {$_(`classes.returnReasonExample.${returnReason}`)}
                      </span>
                    </p>
                  </Option>
                {/each}
              </Select>

              {#if changedReturnStatus === RETURN_STATUS_WAITING || changedReturnStatus === RETURN_STATUS_RETURNING}
                <!-- IF 変更後の返品ステータスが返品待ち または 返品中の場合 -->
                <div class="inputCenterArea">
                  {#if locationList != null}
                    <label class="inputCenterLabel">
                      <select
                        name="inputReturnCenter"
                        class="selectInput"
                        id="inputReturnCenter"
                        bind:value={selectedLocation}
                        on:change={onInputChange}
                      >
                        <option value="" selected disabled
                          >配送センターを選択してください</option
                        >
                        {#each locationList as { prefecture, centers }}
                          <optgroup label={prefecture}>
                            {#each centers as { id, name }}
                              <option value={id}>{name}</option>
                            {/each}
                          </optgroup>
                        {/each}
                      </select>
                    </label>
                  {:else}
                    <select
                      name="failureReturnCenter"
                      class="selectInput"
                      id="failureReturnCenter"
                      disabled
                    >
                      <option selected>取得失敗</option>
                    </select>
                  {/if}
                </div>
              {/if}

              {#if displayReturnStatusError}
                <p class="validationError">未入力の項目があります。</p>
              {/if}
            </div>
          {/if}

          {#if Number.isInteger(shipment?.returnStatus)}
            <!-- IF 返品ステータスが登録済の場合 -->
            <hr style="margin-bottom: 0;" />
            <FormField>
              <Checkbox bind:checked={returnCancelChecked} />
              <span slot="label">返品ステータスの登録を解除する</span>
            </FormField>
          {/if}
        </div>
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  .item {
    width: 650px;
    display: flex;
    gap: 10px;
    position: relative;
    padding: 6px 0;
    border-bottom: 1px solid #eee;
    :global(.mdc-select .mdc-select__menu) {
      max-height: 180px !important;
    }
  }
  .itemTh {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    padding: 10px 0;
    min-width: 120px;
    line-height: 1.2em;
    background-color: #b4d0f1cb;
    color: #242424;
    font-size: smaller;
    font-weight: 900;
    text-align: center;
  }
  .itemTd {
    display: flex;
    flex-grow: 1;
    align-items: center;
    gap: 10px;
  }
  .column {
    flex-direction: column;
  }
  .itemTdLeft {
    width: 400px;
    flex-grow: 1;
    display: flex;
    color: #333;
    font-size: 13px;
    .inputCenterArea {
      .inputCenterLabel {
        display: inline-flex;
        align-items: center;
        position: relative;
        width: 485px;
        margin-top: 8px;
        &::after {
          position: absolute;
          right: 19px;
          width: 10px;
          height: 7px;
          background-color: #666;
          clip-path: polygon(0 0, 100% 0, 50% 76%);
          content: "";
          pointer-events: none;
        }
        select {
          appearance: none;
          width: 100%;
          height: 2.6em;
          padding: 0.4em 30px 0.4em 1.05em;
          border: 1px solid #999;
          border-radius: 3px;
          background-color: #fff;
          color: #333333;
          font-size: 1em;
          cursor: pointer;
        }
        select:hover {
          border-color: #333;
        }
      }
      .selectInput {
        height: 40px;
      }
    }
    .returnRegisterArea {
      margin-top: 12px;
      .title {
        font-size: 15px;
        font-weight: 500;
      }
      .returnReasonItem {
        line-height: 1.2;
        .example {
          display: inline-block;
          margin-top: 4px;
          font-size: 12px;
          color: var(--mdc-theme-secondary);
        }
      }
      :global(.mdc-form-field) {
        width: 100%;
        height: 30px;
      }
    }
  }
  .validationError {
    color: red;
    font-weight: bold;
    margin-top: 8px;
  }
</style>
