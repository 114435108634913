import { format as formatDate } from "date-fns";
import ky, { HTTPError } from "ky";
import { push } from "svelte-spa-router";

import backendApiMocker from "~/libs/backendApiMocker";
import { activeContent } from "~/libs/stores";

/**
 * @typedef {{
 *   data: *,
 *   error?: ErrorResponse,
 * }} JsonResponse
 *
 * @typedef {{
 *   title: string,
 *   message: string,
 *   details: {[key: string]: object},
 * }} ErrorResponse
 *
 * @typedef {{
 *   username: string,
 *   roles: Array<string>,
 *   accessToken: string,
 *   tokenType: string,
 *   expiresIn: number,
 *   displayName: string,
 *   companyId: number,
 *   companyName: string,
 *   currentCompanyId: number,
 *   emailAddress: string,
 *   switchableRoles: Array<string>,
 *   switchableCompanies: Array<Company>,
 * }} LoginResponse
 *
 * @typedef {{
 *   mode: "admin",
 *   response: boolean,
 *   events: Array<UpdateShipmentEvent>,
 * }} UpdateShipmentRequest
 *
 * @typedef {{
 *   trackingNumber: string,
 *   status: 0 | 1 | 2 | 3 | 4,
 *   locationId?: number,
 *   correctedReceiverAddress?: string,
 *   updateTime?: string,
 *   cubicSize?: number,
 *   damaged?: boolean,
 *   unattendedDeliveryPhoto?: string,
 *   signaturePhoto?: string,
 *   actualPackageDropPlace?: 0 | 1 | 2 | 3 | 4 | 5,
 *   needAdjustment?: boolean,
 *   deliveryBoxNumber?: string,
 *   deliveryBoxPin?: string,
 *   extraEvent?: Array<import("~/libs/commonTypes").ExtraEvent>,
 *   extraEventType?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7,
 *   redeliveryContext?: import("~/libs/commonTypes").RedeliveryContext,
 *   delivererId?: number,
 *   driverId?: number,
 *   returnStatus?: 0 | 1 | 2 | 3,
 *   returnReason?: 0 | 1 | 2 | 3 | 4 | 5 | 6,
 *   lost?: boolean,
 *   misdelivered?: boolean,
 *   misdeliveryState?: 0 | 1,
 *   shippingPartnerInternalMessage?: string,
 *   ecDelivererInternalMessage?: string,
 *   delivererInternalMessage?: string,
 *   version?: number,
 *   isManuallyInputted?: boolean,
 *   isIncrementDeliveryAttempt?: boolean,
 *   notification?: Notification,
 *   pushNotification?: PushNotification,
 *   initializeFields?: Array<"photo" | "returnStatus" | "returnReason">,
 * }} UpdateShipmentEvent
 *
 * @typedef {{
 *   type: 0 | 1,
 *   args: any,
 * }} Notification
 *
 * @typedef {PushNotificationMessage & {
 *   data: DriverNotificationData,
 * }} PushNotification
 *
 * @typedef {{
 *   title: string,
 *   body: string,
 * }} PushNotificationMessage
 *
 * @typedef {{
 *   message: PushNotificationMessage,
 *   trackingNumber: string,
 *   correctedReceiverAddress?: string,
 *   ecDelivererInternalMessage?: string,
 *   delivererInternalMessage?: string,
 *   adjustedRedeliveryDatetime?: import("~/libs/commonTypes").DateAndTimeFrame,
 * }} DriverNotificationData
 *
 * @typedef {{
 *   customerOrderId?: string,
 *   trackingNumber?: string,
 *   searchDate: {
 *     start: string,
 *     end?: string,
 *   },
 *   ecId?: number,
 *   shippingUnitId?: string,
 * }} SearchShipmentRequest
 *
 * @typedef {{
 *   maxLimitOver: boolean,
 *   count: number,
 *   shipments: Array<SearchedShipment>,
 *   disabledCount: number,
 * }} SearchShipmentResponse
 *
 * @typedef {{
 *   trackingNumber: string,
 *   status: 0 | 1 | 2 | 3 | 4,
 *   releasedAt: string,
 *   customerName: string,
 *   customerOrderId: string,
 *   receiverPostcode: string,
 *   receiverAddress1: string,
 *   receiverAddress2: string,
 *   correctedReceiverAddress: string,
 *   receiverName: string,
 *   desiredDate: string,
 *   desiredTime: string,
 *   numberOfPackages: number,
 *   cubicSize: number,
 *   relayLocationId: number,
 *   delivererName: string,
 *   driverDisplayName: string,
 *   createdAt: string,
 *   inTransitAt: string,
 *   inTransitLocationId: number,
 *   cashOnDeliveryAmount: number,
 *   numberOfDeliveryAttempts: number,
 *   redeliveryContext: import("~/libs/commonTypes").RedeliveryContext,
 *   specifiedPickupDatetime: import("~/libs/commonTypes").SpecifiedPickupDatetime,
 *   returnStatus: 0 | 1 | 2 | 3,
 *   returnReason: 0 | 1 | 2 | 3 | 4 | 5 | 6,
 *   lost: boolean,
 *   misdelivered: boolean,
 *   misdeliveryState: 0 | 1,
 *   shippingPartnerInternalMessage: string
 * }} SearchedShipment
 *
 * @typedef {{
 *   relayLocationId: number,
 *   statusType: 0 | 1,
 * }} SearchShipmentByLocationIdRequest
 *
 * @typedef {{
 *   supportCashOnDelivery: boolean,
 *   maxCashOnDeliveryAmount: number,
 *   csvUploadCharset: string,
 *   csvUploadHasHeader: boolean,
 *   doesNotGenerateShippingLabel: boolean,
 *   registrableDeliveryMethod : Array<0 | 1 | 2 | 3 | 4 | 5>,
 *   shippingRreceiptUnitUploadCsvHasHeader: boolean,
 *   shippingReceiptUnitUploadCsvTrackingNumberColumnNumber: number,
 * }} EcSettingsResponse
 *
 * @typedef {{
 *  customerOrderId: string,
 *  receiverTel: string,
 *  receiverPostcode: string,
 *  receiverAddress1: string,
 *  receiverAddress2: string,
 *  receiverName: string,
 *  shipperTel: string,
 *  shipperPostcode: string,
 *  shipperAddress1: string,
 *  shipperAddress2: string,
 *  shipperName: string,
 *  receiverEmailAddress: string,
 *  desiredTime: string,
 *  packageDropPlace: string,
 *  cashOnDeliveryAmount: string,
 *  shipperRemarks1: string,
 *  shipperRemarks2: string,
 *  }} UploadShipmentsCsvResponse
 *
 * @typedef {Array<{id: number, name: string}>} EcCompaniesResponse
 *
 * @typedef {{
 *  ecId: number,
 *  searchDate: {
 *   start: string,
 *   end: string
 *  }
 * }} SearchShippingUnitsRequest
 *
 * @typedef {Array<SearchedShippingUnit>} SearchShippingUnitsResponse
 *
 * @typedef {{
 *  releasedBy: "M" | "S",
 *  releasedAt: string,
 *  disabled: boolean,
 *  numberOfShipments: number,
 * }} SearchedShippingUnit
 *
 * @typedef {{
 *   companyId: number,
 *   role: string,
 *   userName?: string,
 *   displayName?: string,
 * }} SearchUserRequest
 *
 * @typedef {{
 *   count: number,
 *   maxLimitOver: boolean,
 *   users: Array<UserInfo>,
 * }} SearchUserResponse
 *
 * @typedef {{
 *   id: number,
 *   displayName: string,
 *   userName: string,
 *   role: string,
 *   emailAddress?: string,
 *   initialPasswordChangeRequired: boolean,
 *   disabled: boolean,
 *   lastLogin: string,
 *   companyId: number,
 *   switchableRoles?: Array<string>,
 *   switchableCompanyIds?: Array<number>,
 *   contactInfo?: string,
 * }} UserInfo
 *
 * @typedef {{
 *   userName: string,
 *   displayName: string,
 *   initialPassword: string,
 *   role: number,
 *   emailAddress?: string,
 *   switchableCompanyIds?: Array<number>,
 *   switchableRoles?: Array<string>,
 *   contactInfo?: string,
 * }} AddUserRequest
 *
 * @typedef {{
 *   userName: string,
 *   updatedUserName?: string,
 *   displayName?: string,
 *   initialPassword?: string,
 *   role?: string,
 *   disabled?: boolean,
 *   emailAddress?: string,
 *   initializeFields?: Array<"emailAddress" | "switchableRoles" | "contactInfo">,
 *   switchableCompanyIds?: Array<number>,
 *   switchableRoles?: Array<string>,
 *   contactInfo?: string
 * }} UpdateUserRequest
 *
 * @typedef {Array<Company>} GetCompaniesResponse
 *
 * @typedef {{
 *   id: number,
 *   name: string,
 *   type: number,
 *   switchableRoles: Array<string>,
 *   switchableCompanies: Array<Company>,
 * }} Company
 *
 * @typedef {{url: string}} signaturePhotoUrlResponse
 *
 * @typedef {{
 *   byAddress?: number,
 *   neighborhood?: number
 * }} DeleteKnowledgeRequest
 *
 * @typedef {{
 *   byAddress: UpdateByAddressKnowledgeRequest,
 *   neighborhood: UpdateNeighborhoodKnowledgeRequest,
 * }} UpdateKnowledgeRequest
 *
 * @typedef {{
 *   id: number,
 *   memo: string,
 *   updatedBy: string,
 *   updatedAt: string,
 *   receiverName: string,
 * }} UpdateByAddressKnowledgeRequest
 *
 * @typedef {{
 *   id: number,
 *   memo: string,
 *   updatedBy: string,
 *   updatedAt: string,
 *   address: string,
 * }} UpdateNeighborhoodKnowledgeRequest
 *
 * @typedef {{
 *   trackingNumbers: Array<string>,
 *   companyId: number,
 *   receiptLocationId: number,
 *   toReceiveOn: string,
 *   sequentialNumber: number,
 *   createdAt: string,
 * }} ShippingReceiptUnitRequest
 *
 * @typedef {{
 *   summary: {
 *     registrationType: 0 | 1 | 2,
 *     shippingReceiptUnitId: number,
 *     total: number,
 *     success: number,
 *     failure: number,
 *   },
 *   numberOfPackagesByLocation: Array<{
 *     locationId: number,
 *     count: number,
 *   }>,
 *   failure: {
 *     notExistsTrackingNumber: Array<string>,
 *     registeredOtherReceiptId: Array<string>,
 *     received: Array<string>,
 *   }
 * }} ShippingReceiptUnitResponse
 *
 * @typedef {{
 *   shippingReceiptUnitId: number,
 *   companyId: number,
 *   receiptLocationId: number,
 *   toReceiveOn: string,
 *   sequentialNumber: number,
 *   createdAt: string,
 *   numberOfShipments: number,
 *   numberOfPackages: number,
 *   numberOfPackagesByLocation: {locationId: number, numberOfPackages: number}[],
 * }} ShippingReceiptUnit
 *
 * @typedef {{
 *   userId: number,
 *   userName: string,
 *   userDisplayName: string,
 *   driverType: 0 | 1,
 *   workTimes: Array<WorkTime>,
 *   results: DeliveryRecordOfDriver & DeliveryRecordOfCoreDelivery
 *   location: {
 *     latitude: number,
 *     longitude: number
 *   },
 *   checked: boolean,
 *   companyId: number,
 *   companyName: string,
 * }} DriverActivityUnit
 *
 * @typedef {{
 *   startAt: string,
 *   endAt: string,
 * }} WorkTime
 *
 * @typedef {{
 *   version : number,                          // バージョン
 *   locationSourceIdList: Array<number>,       // 荷物を持ち出した配送センターidのリスト
 *   undeliveredList: Array<PackageInfo>,       // 未配達荷物情報のリスト
 *   deliveredList: Array<PackageInfo>,         // 配達完了荷物情報のリスト
 *   undeliverableList: Array<PackageInfo>,     // 配達不可荷物情報のリスト
 *   totalDistance: number,                     // 移動距離（単位：m）
 *   deliveryRecords: Array<DeliveryRecord>,    // 配送記録のリスト
 *   updatedAt: string,                         // 更新日時
 * }} DeliveryRecordOfDriver  // ドライバー区分が「宅配」の場合の配達実績
 *
 * @typedef {{
 *   trackingNumber: string,
 *   location: {latitude: number, longitude: number},
 *   address: string,
 * }} PackageInfo
 *
 * @typedef {{
 *   dateTime: string,
 *   recordType: import("~/libs/constants").DeliveryRecordTypes,
 *   location: {latitude: number, longitude: number},
 *   actualLocation?: {latitude: number, longitude: number},
 *   trackingNumber?: string,
 *   address?: string,
 *   extraEvent?: number,
 * }} DeliveryRecord
 *
 * @typedef {{
 *   version : number,                                 // バージョン
 *   inTransitDeliveryList?: Array<InTransitDelivery>, // 輸送中の荷物のリスト
 *   updatedAt: string,                                // 更新日時
 * }} DeliveryRecordOfCoreDelivery  // ドライバー区分が「幹線」の場合の配達実績
 *
 * @typedef {{
 *   transportSourceId: number,                 // 荷受けした拠点のid
 *   deliveryInfoList: Array<{                  // 荷受けした拠点毎の配送情報のリスト
 *     transportDestivationId: number,          // 中継配送センター（配送先）のid
 *     trackingNumberList: Array<string>,       // 中継配送センター（配送先）毎の送り状番号のリスト
 *   }>
 * }} InTransitDelivery  // 輸送中の荷物情報
 *
 * @typedef {{updates: Array<DriverActivityCheckInfo>}} DriverActivityCheckRequest
 *
 * @typedef {{
 *   userId: number,
 *   driverType: 0 | 1,
 *   checked: boolean
 * }} DriverActivityCheckInfo
 */

/** リクエスト・操作を特定するIDのprefix */
const operationIdPrefix = `${formatDate(new Date(), "MMddHHmm")}-${(
  import.meta.env.VITE_COMMIT_HASH || "NA"
).substring(0, 7)}-`;

/** @type {number} リクエスト・操作を特定するIDの連番 */
let operationSequenceNumber = 0;

/** @type {import("ky").KyInstance} */
let kyInstance;

const backendApi = {
  initialize: (
    /** @type {import("~/libs/commonTypes").UserContext} */ userContext,
  ) => {
    kyInstance = createInstance(userContext);
  },

  /**
   * ログイン
   * @param {{username: string, password: string}} data
   * @returns {Promise<LoginResponse>}
   */
  async login(data) {
    /** @type {JsonResponse} */
    const response = await kyInstance.post("login", { json: data }).json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * ログイン（EC切替え）
   * @param {{username: string, password: string}} data
   * @param {number} companyId
   * @returns {Promise<LoginResponse>}
   */
  async loginToSwitchCompany(data, companyId) {
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("login", {
        headers: { "X-Request-Company-Id": String(companyId) },
        json: data,
      })
      .json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * 初期パスワード変更
   * @param {{userName: string, oldPassword: string, newPassword: string}} data
   */
  async changeInitialPassword(data) {
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("users/reset-password", { json: data })
      .json();
    assertNotErrorResponse(response);
  },

  /**
   * パスワードリセット依頼
   * @param {{userName: string, emailAddress: string}} data
   */
  async reqeustPasswordReset(data) {
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("users/password/request-reset", { json: data })
      .json();
    assertNotErrorResponse(response);
  },

  /**
   * パスワードリセット
   * @param {{userName: string, pin: string, newPassword: string}} data
   */
  async passwordReset(data) {
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("users/password/reset", { json: data })
      .json();
    assertNotErrorResponse(response);
  },

  /**
   * パスワード変更
   * @param {{oldPassword: string, newPassword: string}} data
   */
  async changePassword(data) {
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("users/change-password", { json: data })
      .json();
    assertNotErrorResponse(response);
  },

  /**
   * ユーザー取得
   * @returns {Promise<{displayName: string, companyName: string}>}
   */
  async getUser() {
    /** @type {JsonResponse} */
    const response = await kyInstance.get("users").json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * ユーザー情報の登録
   * @param {AddUserRequest} data
   */
  async addUser(data) {
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("users", {
        json: data,
      })
      .json();
    assertNotErrorResponse(response);
  },

  /**
   * ユーザー情報の更新
   * @param {UpdateUserRequest} data
   */
  async updateUser(data) {
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("users/update", {
        json: data,
      })
      .json();
    assertNotErrorResponse(response);
  },

  /**
   * ユーザー検索
   * @param {SearchUserResponse} data
   * @returns {Promise<SearchUserResponse>}
   */
  async searchUsers(data) {
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("users/search", {
        json: data,
      })
      .json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * 会社一覧の取得
   * @returns {Promise<GetCompaniesResponse>}
   */
  async getCompanies() {
    const response = await kyInstance.get("companies").json();
    return /** @type {GetCompaniesResponse} */ (response);
  },

  /**
   * 出荷情報CSVのアップロード
   * @param {FormData} data
   * @returns {Promise<*>} // TODO: 型を定義する
   */
  async uploadShipmentsCsv(data) {
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("shipments/upload", {
        body: data,
      })
      .json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * 出荷情報の登録
   * @param {object} data // TODO: 型を定義する
   * @returns {Promise<{shippingUnitId: string}>}
   */
  async registShipmentInfo(data) {
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("shipments", {
        json: data,
      })
      .json();
    assertNotErrorResponse(response);
    return await response.data;
  },

  /**
   * 送り状PDF・送り状印刷用CSVダウンロード
   * @param {string} shippingUnitId
   * @param {object} data // TODO: 型を定義する
   * @returns {Promise<{url: string}>}
   */
  async downloadShipmentPdfAndCsv(shippingUnitId, data) {
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post(`shipments/${shippingUnitId}/download-shipping-label`, {
        json: data,
        timeout: 100000,
      })
      .json();
    assertNotErrorResponse(response);
    return await response.data;
  },

  /**
   * 出荷情報の検証
   * @param {object} data // TODO: 型を定義する
   */
  async verifyShipment(data) {
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("shipments/valid", {
        json: data,
      })
      .json();
    assertNotErrorResponse(response);
  },

  /**
   * 配送情報の検索
   * @param {SearchShipmentRequest} data
   * @returns {Promise<SearchShipmentResponse>}
   */
  async searchShipments(data) {
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("shipments/search/v2", {
        json: data,
      })
      .json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * 配送情報の検索(配送センターID指定)
   * @param {SearchShipmentByLocationIdRequest} data
   * @returns {Promise<SearchShipmentResponse>}>}
   */
  async searchShipmentsByLocationId(data) {
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("shipments/search/v2/1", {
        json: data,
      })
      .json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * 配送詳細情報の取得
   * @param {string} trackingNumber
   * @param {boolean} personalInfoMasking
   * @returns {Promise<import("~/libs/commonTypes").DetailedShipment>}
   */
  async getDetailedShipment(trackingNumber, personalInfoMasking) {
    /** @type {JsonResponse} */
    const response = await kyInstance
      .get(
        `shipments/${trackingNumber}/details?personalInfoMasking=${personalInfoMasking}`,
      )
      .json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * 配送情報の更新
   * @param {UpdateShipmentEvent} data
   */
  async updateShipment(data) {
    /** @type {UpdateShipmentRequest} */
    const bodyData = {
      mode: "admin",
      response: false,
      events: [data],
    };
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("shipments/update", {
        json: bodyData,
      })
      .json();
    assertNotErrorResponse(response);
  },

  /**
   * 配送情報を検索してCSV形式でダウンロード
   * @param {import("~/libs/backendApi").SearchShipmentRequest} data
   * @returns {Promise<Blob>}
   */
  async searchAndDownloadShipmentsCsv(data) {
    const response = await kyInstance.post("shipments/searchDownload", {
      json: data,
    });
    return await response.blob();
  },

  /**
   * 配送情報を検索してCSV形式でダウンロード(配送センターID指定)
   * @param {import("~/libs/backendApi").SearchShipmentByLocationIdRequest} data
   * @returns {Promise<Blob>}
   */
  async searchAndDownloadShipmentsCsvByLocationId(data) {
    const response = await kyInstance.post("shipments/searchDownload/1", {
      json: data,
    });
    return await response.blob();
  },

  /**
   * 月次集計CSVダウンロード
   * @param {{yearMonth: string}} data
   * @returns {Promise<Blob>}
   */
  async downloadMonthlyAggregationCsv(data) {
    const response = await kyInstance.post("shipments/searchDownload/2", {
      json: data,
    });
    return await response.blob();
  },

  /**
   * 日次レポートExcelダウンロード
   * @param {{companyId: number}} data
   * @returns {Promise<Blob>}
   */
  async downloadDailyReportCsv(data) {
    const response = await kyInstance.post(
      "shipments/searchDownload/3?format=xlsx",
      {
        json: data,
      },
    );
    return await response.blob();
  },

  /**
   * 日次レポート報告済み日時登録
   * @param {{companyId: number, reportedAt: string}} data
   */
  async registerDateReportedOfDailyReport(data) {
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("shipments/reported-at", {
        json: data,
      })
      .json();
    assertNotErrorResponse(response);
  },

  /**
   * 楽天市場利用設定の更新
   * @param {object} data // TODO: 型を定義する
   * @returns {Promise<object>} // TODO: 型を定義する
   */
  async updateRakutenMallSettings(data) {
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("ec-settings/rakuten-email", {
        json: data,
      })
      .json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * ECサイト設定の取得
   * @returns {Promise<EcSettingsResponse>}>}
   */
  async getEcSettings() {
    /** @type {JsonResponse} */
    const response = await kyInstance.get("ec-settings").json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * ECサイト一覧の取得
   * @returns {Promise<EcCompaniesResponse>}
   */
  async getEcCompanies() {
    /** @type {JsonResponse} */
    const response = await kyInstance.get("companies/ec").json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * 発行誤り状態の更新
   * @param {{disabled: boolean}} data
   * @param {string} shippingUnitId
   */
  async disableShippingUnit(data, shippingUnitId) {
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post(`shipping-unit/${shippingUnitId}/disabled`, {
        json: data,
      })
      .json();
    assertNotErrorResponse(response);
  },

  /**
   * 出荷単位一覧の検索
   * @param {SearchShippingUnitsRequest} data
   * @returns {Promise<SearchShippingUnitsResponse>}
   */
  async searchShippingUnits(data) {
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("shipping-unit/search", {
        json: data,
      })
      .json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * 受領証跡写真URLの取得
   * @param {string} trackingNumber
   * @returns {Promise<signaturePhotoUrlResponse>}
   */
  async getSignaturePhotoUrl(trackingNumber) {
    /** @type {JsonResponse} */
    const response = await kyInstance
      .get(`shipments/${trackingNumber}/signature-photo-url`)
      .json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * 配送センター一覧の取得
   * @returns {Promise<Array<import("~/libs/commonTypes").DepotLocation>>}
   */
  async getDepotLocations() {
    /** @type {JsonResponse} */
    const response = await kyInstance.get("locations").json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * 配達ナレッジ一覧の取得
   * @returns {Promise<Array<import("~/libs/commonTypes").ShippingKnowledge>>}
   */
  async getShippingKnowledge() {
    /** @type {JsonResponse} */
    const response = await kyInstance.get("shipping-knowledge").json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * 配達ナレッジの更新
   * @param {UpdateKnowledgeRequest} data
   */
  async updateShippingKnowledge(data) {
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("shipping-knowledge/update", {
        json: data,
      })
      .json();
    assertNotErrorResponse(response);
  },

  /**
   * 配達ナレッジの削除
   * @param {DeleteKnowledgeRequest} data
   */
  async deleteShippingKnowledge(data) {
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("shipping-knowledge/delete", {
        json: data,
      })
      .json();
    assertNotErrorResponse(response);
  },

  /**
   * 出荷確定データの登録
   * @param {ShippingReceiptUnitRequest} data
   * @param {"0" | "1"} mode
   * @returns {Promise<ShippingReceiptUnitResponse>}
   */
  async registShippingReceiptUnit(data, mode) {
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post(`shipping-receipt-unit/?mode=${mode}`, {
        json: data,
      })
      .json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * 荷受け情報一覧の取得
   * @returns {Promise<Array<ShippingReceiptUnit>>}
   */
  async getShippingReceiptUnitList() {
    /** @type {JsonResponse} */
    const response = await kyInstance.get("shipping-receipt-unit").json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * ドライバー稼働状況一覧の取得
   * @returns {Promise<[
   *   Array<DriverActivityUnit>, // ドライバー稼働状況一覧
   *   boolean                    // ドライバー稼働状況の更新が停止中かどうか(停止中の場合にtrue)
   * ]>}
   */
  async getDriverActivites() {
    const response = await kyInstance.get("driver-activity");
    /** @type {JsonResponse} */
    const responseBody = await response.json();
    assertNotErrorResponse(responseBody);

    // ヘッダ値の取得
    const enabledSyncDriverActivity = response.headers.get(
      "x-enabled-sync-driver-activity",
    );

    return [responseBody.data, enabledSyncDriverActivity === "false"];
  },

  /**
   * ドライバー稼働状況の確認済みフラグを更新
   * @param {DriverActivityCheckRequest} data
   */
  async updateDriverActivityCheck(data) {
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post(`driver-activity/checked`, {
        json: data,
      })
      .json();
    assertNotErrorResponse(response);
  },
};
export default backendApi;

/**
 * バックエンドからerrorプロパティを持つレスポンスを受け取った場合に発生する例外
 */
export class ErrorResponseException extends Error {
  errorResponse;

  /**
   * @param {ErrorResponse} errorResponse
   */
  constructor(errorResponse) {
    super("Receive error response");
    this.name = "ErrorResponseException";
    this.errorResponse = errorResponse;
  }
}

/**
 * kyのインスタンスを生成する。
 * @param {import("~/libs/commonTypes").UserContext} [userContext]
 * @returns {import("ky").KyInstance}
 */
function createInstance(userContext) {
  return ky.extend({
    prefixUrl: import.meta.env.VITE_API_SERVER_URL,
    timeout: 60000,
    parseJson: (text) => (text !== "" ? JSON.parse(text) : null),
    hooks: {
      beforeRequest: [
        (request, options) => {
          // 認証トークンの設定
          if (userContext?.loginUser?.accessToken) {
            request.headers.set(
              "Authorization",
              "Bearer " + userContext.loginUser.accessToken,
            );
          }

          // リクエスト・操作を特定するIDを設定
          request.headers.set(
            "X-Operation-Id",
            operationIdPrefix + ++operationSequenceNumber,
          );

          // モック用のリクエスト書き換え処理
          _REMOVABLE_MOCK_: {
            if (import.meta.env.VITE_USE_MOCK_API) {
              backendApiMocker.prepareRequest(request, options);
            }
            break _REMOVABLE_MOCK_; // 未使用ラベルがViteの事前処理で削除されてesbuildに渡せない対策
          }
        },
      ],

      beforeError: [
        async (error) => {
          if (
            error.response?.headers
              ?.get("Content-Type")
              ?.match(/^application\/json(?:;|$)/)
          ) {
            try {
              /** @type {JsonResponse} */
              const response = await error.response.json();
              if (response?.error) {
                error["errorResponse"] = response.error;
              }
              if (response?.data) {
                // XXX: 本来エラーパターンではdataプロパティはないはずだが、adminはBEの実装上ありえる
                error["dataResponse"] = response.data;
              }
            } catch (error) {
              console.error(error);
            }
          }

          // ログイン済の場合はアクセストークンを無効化してログイン画面へ強制的に移動
          if (
            error instanceof HTTPError &&
            error.response?.status == 401 &&
            userContext.loginUser
          ) {
            console.error("APIで認証エラーが発生したためログイン画面へ移動");
            userContext.loginUser.accessToken = undefined;
            activeContent.set(null);
            push("/");
          }

          return error;
        },
      ],
    },
  });
}

/**
 * errorプロパティを持つレスポンスでないことをアサートする。
 * @param {JsonResponse} response
 */
function assertNotErrorResponse(response) {
  if (response.error) {
    throw new ErrorResponseException(response.error);
  }
}
