<script>
  import { createEventDispatcher, getContext } from "svelte";
  import { _ } from "svelte-i18n";

  import {
    CONTEXT_KEY_USER,
    STATUS_DELIVERED,
    STATUS_OUT_FOR_DELIVERY,
  } from "~/libs/constants";
  import { formatTrackingNumber } from "~/libs/utils";
  import ActualPackageDropPlace from "~/pages/Search/Update/EditableFields/ActualPackageDropPlace.svelte";
  import CorrectedReceiverAddress from "~/pages/Search/Update/EditableFields/CorrectedReceiverAddress.svelte";
  import Damaged from "~/pages/Search/Update/EditableFields/Damaged.svelte";
  import RedeliveryDateTime from "~/pages/Search/Update/EditableFields/RedeliveryDateTime.svelte";
  import SignaturePhoto from "~/pages/Search/Update/EditableFields/SignaturePhoto.svelte";
  import Status from "~/pages/Search/Update/EditableFields/Status.svelte";
  import Troubles from "~/pages/Search/Update/EditableFields/Troubles.svelte";
  import UnattendedDeliveryPhoto from "~/pages/Search/Update/EditableFields/UnattendedDeliveryPhoto.svelte";

  /** @type {import("~/libs/commonTypes").DetailedShipment} */
  export let shipment;

  let dispatch = createEventDispatcher();

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  let changedStatus;

  /** @type {boolean} 置き配写真を表示するかどうかを示すフラグ*/
  let displayUnattendedDeliveryPhoto = false;

  /** @type {boolean} 配達票写真を表示するかどうかを示すフラグ*/
  let displaysignaturePhoto = false;

  /** @type {object} 登録ボタンを有効化してよいか判断するための一覧 */
  let registerButtonState = {
    status: null,
    redeliveryContext: null,
    correctedReceiverAddress: null,
    actualPackageDropPlace: null,
    unattendedDeliveryPhoto: null,
    signaturePhoto: null,
    damaged: null,
    troubles: null,
  };

  /** @type {object} 更新する荷物情報を保持する */
  let keptUpdateShipmentEvent = {};

  // ページの初期化処理（非同期）
  (async () => {
    changedStatus = shipment.status;
    judgeDisplayPhoto();
  })();

  /**
   * 置き配写真と配達票写真の出し分けを行う
   */
  function judgeDisplayPhoto() {
    if (shipment?.signatureRequired) {
      // 配達証明の種別が＜受領印もしくは署名＞の場合
      displaysignaturePhoto = true;
    } else {
      // 配達証明の種別が＜手渡し：なし、置き配：写真＞の場合
      if (shipment?.unattendedDeliveryPhotoUploaded) {
        // 置き配写真が登録されている場合
        displayUnattendedDeliveryPhoto = true;
      } else if (shipment?.signaturePhotoUploaded) {
        // 配達票写真が登録されている場合
        displaysignaturePhoto = true;
      } else {
        // 置き配写真も配達票写真も登録されていない場合
        displayUnattendedDeliveryPhoto = true;
      }
    }
  }

  /**
   * スクロール時にSelectのメニューを非表示にする
   */
  function scrollEvent() {
    for (
      let i = 0;
      i <
      document.getElementsByClassName("mdc-select mdc-select--activated")
        .length;
      i++
    ) {
      let activeSelect = document.getElementsByClassName(
        "mdc-select mdc-select--activated",
      )[i];
      let activeMenu = activeSelect.getElementsByClassName(
        "mdc-select__menu mdc-menu-surface--open",
      )[0];

      activeMenu.classList.remove(
        "mdc-menu-surface--open",
        "mdc-menu-surface--is-open-below",
      );
      activeSelect.classList.remove(
        "mdc-select--activated",
        "mdc-select--focused",
      );
    }
  }

  /**
   * 登録ボタンを有効化するかどうかを判定する
   * @param {CustomEvent} event
   * @returns {boolean} 登録ボタンを有効化するかどうか
   */
  function updateRegisterButtonState(event) {
    registerButtonState[event.detail.updateFieldName] =
      event.detail.isAllConditionsMet;

    // 汚損以外の項目が1つでも登録されている場合、damagedの値を無視する
    const otherFields = { ...registerButtonState };
    delete otherFields.damaged;
    const isOtherFieldsValid = Object.values(otherFields).some(
      (value) => value === true,
    );
    if (isOtherFieldsValid) {
      registerButtonState.damaged = null;
    }

    for (const key in registerButtonState) {
      if (registerButtonState[key] === false) {
        // 未入力の項目がある場合は登録ボタンを無効化
        return false;
      }
    }
    return true;
  }

  /**
   * 更新する荷物情報を作成する
   * @param {CustomEvent} event
   * @returns {Promise<import("~/libs/backendApi").UpdateShipmentEvent>} 更新する荷物情報
   */
  async function createUpdateShipmentEvent(event) {
    /** @type {import("~/libs/backendApi").UpdateShipmentEvent} 更新する荷物情報 */
    const updateShipmentEvent = {
      trackingNumber: shipment.trackingNumber,
      status: shipment.status,
      version: shipment.version,
    };
    if (keptUpdateShipmentEvent) {
      // 保持している更新情報がある場合は、それを引き継ぐ
      Object.assign(updateShipmentEvent, keptUpdateShipmentEvent);
    }

    if (event.detail.updateFieldName === "redeliveryContext") {
      // 再配達希望日時が変更された場合
      updateShipmentEvent.redeliveryContext =
        event.detail.updateShipmentEvent.redeliveryContext;

      if (shipment.status === STATUS_OUT_FOR_DELIVERY && shipment.driverId) {
        // 持出中の場合はドライバーに通知する
        const message = {
          title: $_(
            "pages.Search.pushNotificationMessage.title.adjustedRedeliveryDatetime",
          ),
          body: $_("pages.Search.pushNotificationMessage.body", {
            values: {
              trackingNumber: formatTrackingNumber(shipment.trackingNumber),
            },
          }),
        };
        updateShipmentEvent.pushNotification = {
          title: message.title,
          body: message.body,
          data: {
            message: message,
            trackingNumber: shipment.trackingNumber,
            adjustedRedeliveryDatetime:
              event.detail.updateShipmentEvent.redeliveryContext
                .adjustedRedeliveryDatetime,
          },
        };
      }
    } else if (event.detail.updateFieldName === "correctedReceiverAddress") {
      // 荷受人の住所が変更された場合
      updateShipmentEvent.correctedReceiverAddress =
        event.detail.updateShipmentEvent.correctedReceiverAddress;

      if (shipment.status === STATUS_OUT_FOR_DELIVERY && shipment.driverId) {
        // 持出中の場合はドライバーに通知する
        if (!updateShipmentEvent.pushNotification) {
          // pushNotificationが未設定の場合は通知メッセージも含め設定
          const message = {
            title: $_(
              "pages.Search.pushNotificationMessage.title.correctedReceiverAddress",
            ),
            body: $_("pages.Search.pushNotificationMessage.body", {
              values: { trackingNumber: shipment.trackingNumber },
            }),
          };
          updateShipmentEvent.pushNotification = {
            title: message.title,
            body: message.body,
            data: {
              message: message,
              trackingNumber: shipment.trackingNumber,
              correctedReceiverAddress:
                event.detail.updateShipmentEvent.correctedReceiverAddress,
            },
          };
        } else {
          // pushNotificationが設定済(再配達希望日時も同時に変更あり)の場合は更新データのみ設定
          updateShipmentEvent.pushNotification.data.correctedReceiverAddress =
            event.detail.updateShipmentEvent.correctedReceiverAddress;
        }
      }
    } else if (event.detail.updateFieldName === "actualPackageDropPlace") {
      // 実際の配達方法が変更された場合
      judgeDisplayPhoto();
      updateShipmentEvent.actualPackageDropPlace =
        event.detail.updateShipmentEvent.actualPackageDropPlace;
    } else if (
      displayUnattendedDeliveryPhoto &&
      event.detail.updateFieldName === "unattendedDeliveryPhoto"
    ) {
      // 置き配写真が選択された場合
      updateShipmentEvent.unattendedDeliveryPhoto =
        event.detail.updateShipmentEvent.unattendedDeliveryPhoto;
    } else if (event.detail.updateFieldName === "signaturePhoto") {
      // 配達票写真が選択されている場合
      updateShipmentEvent.signaturePhoto =
        event.detail.updateShipmentEvent.signaturePhoto;
    } else if (event.detail.updateFieldName === "damaged") {
      // 輸送中の外装汚損の発生有無が変更された場合
      updateShipmentEvent.damaged = event.detail.updateShipmentEvent.damaged;
    } else if (event.detail.updateFieldName === "troubles") {
      // 発生事象が変更されている場合
      updateShipmentEvent.extraEvent =
        event.detail.updateShipmentEvent.extraEvent;
    } else if (event.detail.updateFieldName === "status") {
      // ステータスが変更された場合
      if (event.detail.updateShipmentEvent.status !== undefined) {
        changedStatus = event.detail.updateShipmentEvent.status;
        updateShipmentEvent.status = event.detail.updateShipmentEvent.status;
      }
      if (event.detail.updateShipmentEvent.returnStatus !== undefined) {
        updateShipmentEvent.returnStatus =
          event.detail.updateShipmentEvent.returnStatus;
      }
      if (event.detail.updateShipmentEvent.returnReason !== undefined) {
        updateShipmentEvent.returnReason =
          event.detail.updateShipmentEvent.returnReason;
      }
      if (event.detail.updateShipmentEvent.locationId !== undefined) {
        updateShipmentEvent.locationId =
          event.detail.updateShipmentEvent.locationId;
      }
    }
    // 初期化するフィールドがある場合
    if (event.detail.initializeFields) {
      updateShipmentEvent.initializeFields = event.detail.initializeFields;
    }

    keptUpdateShipmentEvent = updateShipmentEvent;
    return updateShipmentEvent;
  }

  /**
   * 入力内容が変更されたとき、SearchResultUpdatePattern.svelteにイベントを発行する。
   * @param {CustomEvent} event
   */
  async function onInputChange(event) {
    const updateShipmentEvent = await createUpdateShipmentEvent(event);
    // イベントを発行
    dispatch("inputChange", {
      isAllConditionsMet: updateRegisterButtonState(event),
      updateShipmentEvent: updateShipmentEvent,
    });
  }
</script>

<div class="wrapper" on:scroll={scrollEvent}>
  <Status {shipment} on:inputChange={onInputChange} />

  {#if userContext.hasShippingPartnerAdminRole()}
    <RedeliveryDateTime {shipment} on:inputChange={onInputChange} />
  {/if}

  {#if userContext.hasShippingPartnerAdminRole()}
    <CorrectedReceiverAddress on:inputChange={onInputChange} />
  {/if}

  {#if !userContext.hasEcAdminRole() && changedStatus === STATUS_DELIVERED}
    <ActualPackageDropPlace {shipment} on:inputChange={onInputChange} />
  {/if}

  {#if displayUnattendedDeliveryPhoto && changedStatus === STATUS_DELIVERED}
    <UnattendedDeliveryPhoto on:inputChange={onInputChange} />
  {/if}

  {#if displaysignaturePhoto && changedStatus === STATUS_DELIVERED}
    <SignaturePhoto on:inputChange={onInputChange} />
  {/if}

  <Damaged {shipment} on:inputChange={onInputChange} />

  <Troubles {shipment} on:inputChange={onInputChange} />
</div>
