<script>
  import { datadogLogs } from "@datadog/browser-logs";
  import { SvelteToast } from "@zerodevx/svelte-toast";
  import { getContext, setContext } from "svelte";
  import {
    addMessages,
    getLocaleFromNavigator,
    init as i18nInit,
  } from "svelte-i18n";
  import Router from "svelte-spa-router";

  import backendApi from "~/libs/backendApi";
  import { AppContext, UserContext } from "~/libs/commonTypes";
  import {
    CONTEXT_KEY_APP,
    CONTEXT_KEY_USER,
    TOAST_DURATION,
  } from "~/libs/constants";
  import localStorages from "~/libs/localStorages";
  import logger from "~/libs/logger";
  import { targetTrackingNumber } from "~/libs/stores";
  import i18n_ja from "~/locales/ja.json";
  import Login from "~/pages/Login.svelte";
  import Home from "~/pages/Main.svelte";

  // パラメータから送り状番号を取得
  const trackingNumber = new URLSearchParams(location.search).get("t");
  $: if (trackingNumber) {
    targetTrackingNumber.set(trackingNumber);
  }

  // 全ページで共有するアプリケーションのコンテキスト情報を初期化（Context APIでアクセス）
  try {
    // アプリケーション固有のコンテキスト情報
    const appContext = new AppContext();
    if (localStorages.appContext) {
      const storedAppContext = JSON.parse(localStorages.appContext);
      Object.assign(appContext, storedAppContext);
    }
    setContext(CONTEXT_KEY_APP, appContext);

    // ユーザ固有のコンテキスト情報
    const userContext = new UserContext();
    if (localStorages.userContext) {
      const storedUserContext = JSON.parse(localStorages.userContext);
      Object.assign(userContext, storedUserContext);
    }
    setContext(CONTEXT_KEY_USER, userContext);

    window.addEventListener("beforeunload", () => {
      // Windowが閉じられる前に、コンテキスト情報をlocalStorageに保存
      userContext.store();
    });

    console.log(userContext);
  } catch (error) {
    console.error(error);
  }

  addMessages("ja", i18n_ja);
  i18nInit({
    fallbackLocale: "ja",
    initialLocale: getLocaleFromNavigator(),
  });

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  // kyの初期化
  backendApi.initialize(userContext);

  const routes = {
    "/": Login,
    "/home": Home,
    "*": Login,
  };

  // Datadogを使用しない環境用の書き換え処理
  _REMOVABLE_UNUSED_DATADOG_: {
    // Initialize Datadog logger
    if (!import.meta.env.DEV) {
      datadogLogs.init({
        clientToken: "pub3742f1b67b032ddc0bbe777ec305b9a4",
        site: "ap1.datadoghq.com",
        service: "raptortms-admin",
        env: import.meta.env.MODE,
        version: (import.meta.env.VITE_COMMIT_HASH || "n/a").substring(0, 7),
        forwardErrorsToLogs: false,
      });
      break _REMOVABLE_UNUSED_DATADOG_; // 未使用ラベルがViteの事前処理で削除されてesbuildに渡せない対策
    }
  }
</script>

<svelte:window
  on:unhandledrejection|preventDefault={(event) => {
    try {
      const message = `window.onunhandledrejection: ${event.reason}`;

      logger.error(
        message,
        { username: userContext.loginUser?.username },
        event.reason instanceof Error ? event.reason : undefined,
      );
    } catch (error) {
      console.error(error);
    }
  }}
  on:error|preventDefault={(event, source, lineno, colno, error) => {
    try {
      let message = "window.onerror: ";
      if (event instanceof ErrorEvent) {
        if (
          event.message ===
            "Uncaught Error: Tried to dipatch event without element." ||
          event.message === "Error: Tried to dipatch event without element."
        ) {
          // SMUIのMenuをフォーカスアウトで閉じたときに発生するエラーは無視
          return;
        }
        message += event.message;
        error = event.error;
      } else {
        message += `${event} (${source}:${lineno}:${colno})`;
      }

      logger.error(
        message,
        { username: userContext.loginUser?.username },
        error instanceof Error ? error : undefined,
      );
    } catch (error) {
      console.error(error);
    }
  }}
/>

<Router {routes} />

<SvelteToast
  options={{
    duration: TOAST_DURATION,
    pausable: false,
  }}
/>
