<script>
  import { createEventDispatcher, onMount } from "svelte";

  /** @type {import("~/libs/commonTypes").DetailedShipment} */
  export let shipment;

  let dispatch = createEventDispatcher();

  // ページの初期化処理
  onMount(() => {
    onInputChange();
  });

  /**
   * 入力内容が変更されたとき、SearchResultUpdatePattern.svelteにイベントを発行する。
   */
  function onInputChange() {
    /** @type {boolean} 登録に必要な条件が全てそろっているか判定するフラグ */
    // 誤送登録解除の場合は、了承チェックが不要
    let isAllConditionsMet = true;

    /** @type {import("~/libs/backendApi").UpdateShipmentEvent} 更新する荷物情報 */
    const updateShipmentEvent = {
      trackingNumber: shipment.trackingNumber,
      status: shipment.status,
      version: shipment.version,
    };
    updateShipmentEvent.misdelivered = false;

    // イベントを発行
    dispatch("inputChange", {
      isAllConditionsMet,
      updateShipmentEvent,
    });
  }
</script>

<div class="wrapper">
  <div class="noteArea">
    <p>この荷物の<b>配達不能（誤送）</b>状態を解除します。</p>
    <p>
      「通信欄(配送事業者内)」欄は自動で削除されないため、必要に応じて該当する内容の削除を行ってください。
    </p>
  </div>
</div>
