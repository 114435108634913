<script>
  import { createEventDispatcher } from "svelte";

  import { EditPattern } from "~/libs/constants";
  import CancelDeliveryRegistration from "~/pages/Search/Update/CancelDeliveryRegistration.svelte";
  import CorrectAddressRegistration from "~/pages/Search/Update/CorrectAddressRegistration.svelte";
  import DeliveryPhotoRegistration from "~/pages/Search/Update/DeliveryPhotoRegistration.svelte";
  import DeliveryStatusCorrection from "~/pages/Search/Update/DeliveryStatusCorrection.svelte";
  import LostCancellation from "~/pages/Search/Update/LostCancellation.svelte";
  import LostRegistration from "~/pages/Search/Update/LostRegistration.svelte";
  import MisdeliveryCancellation from "~/pages/Search/Update/MisdeliveryCancellation.svelte";
  import MisdeliveryRegistration from "~/pages/Search/Update/MisdeliveryRegistration.svelte";
  import OtherRegistration from "~/pages/Search/Update/OtherRegistration.svelte";
  import RedeliveryRegistration from "~/pages/Search/Update/RedeliveryRegistration.svelte";
  import ReturnRegistration from "~/pages/Search/Update/ReturnRegistration.svelte";
  import TroublesRegistration from "~/pages/Search/Update/TroublesRegistration.svelte";

  /** @type {import("~/libs/commonTypes").DetailedShipment} */
  export let shipment;
  /** @type {number} */
  export let changedEditPattern;

  let dispatch = createEventDispatcher();

  /**
   * 入力内容が変更されたとき、SearchResultUpdate.svelteにイベントを発行する。
   * @param {CustomEvent} event
   */
  function handleInputChange(event) {
    updateRegisterButtonState(event);
    handleUpdateShipmentEvent(event);
  }

  /**
   * 登録ボタンの有効化状態を更新する。
   * @param {CustomEvent} event
   */
  function updateRegisterButtonState(event) {
    /** @type {boolean} 登録ボタンの無効化フラグ */
    let registerButtonDisabled = true;

    if (event.detail.isAllConditionsMet) {
      //  登録に必要な条件が全てそろっていれば登録ボタンを有効化
      registerButtonDisabled = false;
    }

    dispatch("enableRegisterButton", {
      registerButtonDisabled: registerButtonDisabled,
    });
  }

  /**
   * 荷物情報の更新イベントを発行する。
   * @param {CustomEvent} event
   */
  function handleUpdateShipmentEvent(event) {
    /** @type {import("~/libs/backendApi").UpdateShipmentEvent} 更新する荷物情報 */
    let updateShipmentEvent;

    if (event.detail.updateShipmentEvent) {
      // 更新する荷物情報を作成
      updateShipmentEvent = event.detail.updateShipmentEvent;
    }

    dispatch("updateShipmentEvent", {
      updateShipmentEvent: updateShipmentEvent,
    });
  }
</script>

<section>
  <!-- 0: 配達キャンセル登録 -->
  {#if changedEditPattern === EditPattern.CANCEL_DELIVERY}
    <CancelDeliveryRegistration {shipment} on:inputChange={handleInputChange} />
    <!-- 1: 紛失登録 -->
  {:else if changedEditPattern === EditPattern.LOST}
    <LostRegistration {shipment} on:inputChange={handleInputChange} />
    <!-- 2: 紛失登録解除 -->
  {:else if changedEditPattern === EditPattern.LOST_CANCEL}
    <LostCancellation {shipment} on:inputChange={handleInputChange} />
    <!-- 3: 誤送登録 -->
  {:else if changedEditPattern === EditPattern.MISDELIVERY}
    <MisdeliveryRegistration {shipment} on:inputChange={handleInputChange} />
    <!-- 4: 誤送登録解除 -->
  {:else if changedEditPattern === EditPattern.MISDELIVERY_CANCEL}
    <MisdeliveryCancellation {shipment} on:inputChange={handleInputChange} />
    <!-- 5: 返品登録 -->
  {:else if changedEditPattern === EditPattern.RETURN}
    <ReturnRegistration {shipment} on:inputChange={handleInputChange} />
    <!-- TODO: 6: 荷受できていない荷物として登録 -->
    <!-- 7: 再配達依頼の登録 -->
  {:else if changedEditPattern === EditPattern.REDELIVERY}
    <RedeliveryRegistration {shipment} on:inputChange={handleInputChange} />
    <!-- 8: 訂正住所の登録 -->
  {:else if changedEditPattern === EditPattern.CORRECT_ADDRESS}
    <CorrectAddressRegistration {shipment} on:inputChange={handleInputChange} />
    <!-- 9: 置き配写真の登録 -->
  {:else if changedEditPattern === EditPattern.DELIVERY_PHOTO}
    <DeliveryPhotoRegistration {shipment} on:inputChange={handleInputChange} />
    <!-- 10: 配送中の発生事象の登録 -->
  {:else if changedEditPattern === EditPattern.TROUBLES}
    <TroublesRegistration {shipment} on:inputChange={handleInputChange} />
    <!-- 11: 配送ステータスの修正 -->
  {:else if changedEditPattern === EditPattern.DELIVERY_STATUS}
    <DeliveryStatusCorrection {shipment} on:inputChange={handleInputChange} />
    <!-- 12: その他 -->
  {:else if changedEditPattern === EditPattern.OTHER}
    <OtherRegistration {shipment} on:inputChange={handleInputChange} />
  {/if}
</section>
