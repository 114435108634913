<script>
  import Checkbox from "@smui/checkbox";
  import FormField from "@smui/form-field";
  import { format as formatDate } from "date-fns";
  import { ja as localeJa } from "date-fns/locale";
  import { createEventDispatcher, getContext } from "svelte";

  import { CHOICES_OF_TIME, CONTEXT_KEY_USER } from "~/libs/constants";
  import desiredDateTime from "~/libs/desiredDateTime";
  import { getCurrentDateTimeOnJst } from "~/libs/utils";

  /** @type {import("~/libs/commonTypes").DetailedShipment} */
  export let shipment;

  let dispatch = createEventDispatcher();

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /**
   * 再配達希望日の表示用リスト
   * @type {Array<import("~/libs/commonTypes").DateAndTimeFrame>}
   **/
  let displayDateAndTimeList = [];

  /** @type {string} 変更後の再配達希望日*/
  let changedAdjustedDate;

  /** @type {string} 変更後の再配達希望時間帯（開始）*/
  let changedStartOfAdjustedTime = "00";

  /** @type {string} 変更後の再配達希望時間帯（終了）*/
  let changedEndOfAdjustedTime = "24";

  /** @type {boolean}「時間帯を指定しない」にチェックが入っているかどうかを保持する変数 */
  let noTimeSpecifiedChecked = false;

  /** @type {Date} 本日の日付 */
  const today = getCurrentDateTimeOnJst();

  // ページの初期化処理（非同期）
  (async () => {
    // 現在登録されている配達希望日時、再配達希望日時をもとに初期値を設定
    displayDateAndTimeList = desiredDateTime.resolve(shipment);
    if (displayDateAndTimeList.length == 1) {
      const date = displayDateAndTimeList[0].date;
      const timeFrame = displayDateAndTimeList[0].timeFrame;
      // 日付の初期値設定
      if (date) {
        if (new Date(date) >= today) {
          // 現在登録されている再配達希望日時が今日以降の場合はデフォルト値として設定
          changedAdjustedDate = date;
        }
      }
      // 時間の初期値設定
      if (timeFrame) {
        changedStartOfAdjustedTime = timeFrame.slice(0, 2);
        changedEndOfAdjustedTime = timeFrame.slice(2, 4);
      }
    }
  })();

  /**
   * 登録ボタンを有効化するかどうかを判定する
   * @returns {boolean} 登録ボタンを有効化するかどうか
   */
  function updateRegisterButtonState() {
    /** @type {boolean} 登録に必要な条件が全てそろっているか判定するフラグ */
    let isAllConditionsMet = false;

    if (
      changedAdjustedDate !== undefined &&
      (changedStartOfAdjustedTime !== "00" || changedEndOfAdjustedTime !== "24")
    ) {
      // 日付、時間帯が新たに指定されていれば、登録に必要な条件が全てそろっていると判定
      isAllConditionsMet = true;
    } else if (changedAdjustedDate && noTimeSpecifiedChecked) {
      // 日付が指定され、「時間帯を指定しない」がチェックされていれば、登録に必要な条件が全てそろっていると判定
      isAllConditionsMet = true;
    }

    return isAllConditionsMet;
  }

  /**
   * 更新する荷物情報を作成する
   * @returns {Promise<import("~/libs/backendApi").UpdateShipmentEvent>} 更新する荷物情報
   */
  async function createUpdateShipmentEvent() {
    /** @type {import("~/libs/backendApi").UpdateShipmentEvent} 更新する荷物情報 */
    const updateShipmentEvent = {};

    let adjustedRedeliveryDatetime;
    if (changedAdjustedDate) {
      // 再配達希望日が指定されている場合
      if (!noTimeSpecifiedChecked) {
        // 再配達依頼の時間帯が指定されている場合
        adjustedRedeliveryDatetime = {
          date: changedAdjustedDate,
          timeFrame: changedStartOfAdjustedTime + changedEndOfAdjustedTime,
        };
      } else {
        adjustedRedeliveryDatetime = {
          date: changedAdjustedDate,
          timeFrame: null,
        };
      }
      updateShipmentEvent.redeliveryContext = {
        adjustedRedeliveryDatetime: adjustedRedeliveryDatetime,
      };
    }

    return updateShipmentEvent;
  }

  /**
   * 入力内容が変更されたとき、親コンポーネントにイベントを発行する。
   */
  async function onInputChange() {
    const updateShipmentEvent = await createUpdateShipmentEvent();
    // イベントを発行
    dispatch("inputChange", {
      updateFieldName: "redeliveryContext",
      isAllConditionsMet: updateRegisterButtonState(),
      updateShipmentEvent: updateShipmentEvent,
    });
  }
</script>

{#if userContext.hasShippingPartnerAdminRole()}
  <div class="item" id="redeliveryDateTime">
    <div class="itemTh">再配達希望日時</div>
    <div class="itemTd">
      <div>
        <div class="dateAndTimeSelectArea">
          <label class="selectLabel" for="deliveryDate">日付</label>
          <div class="dateSelect">
            <input
              id="deliveryDate"
              type="date"
              class="inputDesiredDate"
              min={formatDate(today, "yyyy-MM-dd", {
                locale: localeJa,
              })}
              bind:value={changedAdjustedDate}
              on:change={onInputChange}
            />
          </div>
        </div>
        <div class="dateAndTimeSelectArea">
          <label class="selectLabel" for="deliveryTime">時間</label>
          <div class="deliveryTimeSelect">
            <label for="startOfAdjustedTime" class="deliveryTimeLabel">
              <select
                name="startOfAdjustedTime"
                id="startOfAdjustedTime"
                class="deliveryTime"
                bind:value={changedStartOfAdjustedTime}
                on:change={onInputChange}
                disabled={noTimeSpecifiedChecked}
              >
                {#each CHOICES_OF_TIME as time, index}
                  {#if index !== CHOICES_OF_TIME.length - 1 && (changedEndOfAdjustedTime !== "" ? time < changedEndOfAdjustedTime : true)}
                    <option value={time} style="height: 30px"
                      >{Number(time)}時</option
                    >
                  {/if}
                {/each}
              </select>
            </label>
            <span>～</span>
            <label for="endOfAdjustedTime" class="deliveryTimeLabel">
              <select
                name="endOfAdjustedTime"
                id="endOfAdjustedTime"
                class="deliveryTime"
                bind:value={changedEndOfAdjustedTime}
                on:change={onInputChange}
                disabled={noTimeSpecifiedChecked}
              >
                {#each CHOICES_OF_TIME as time, index}
                  {#if index !== 0 && (changedStartOfAdjustedTime !== "" ? time > changedStartOfAdjustedTime : true)}
                    <option value={time} style="height: 30px"
                      >{Number(time)}時</option
                    >
                  {/if}
                {/each}
              </select>
            </label>
          </div>
        </div>
        <div class="desiredTimeCheckbox">
          <FormField>
            <Checkbox
              bind:checked={noTimeSpecifiedChecked}
              on:change={onInputChange}
            />
            <span slot="label">時間帯を指定しない</span>
          </FormField>
        </div>
      </div>
    </div>
  </div>
{/if}

<style lang="scss">
  .item {
    width: 650px;
    display: flex;
    gap: 10px;
    position: relative;
    padding: 6px 0;
    border-bottom: 1px solid #eee;
  }
  .itemTh {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    padding: 10px 0;
    min-width: 120px;
    line-height: 1.2em;
    background-color: #b4d0f1cb;
    color: #242424;
    font-size: smaller;
    font-weight: 900;
    text-align: center;
  }
  .itemTd {
    display: flex;
    flex-grow: 1;
    align-items: center;
    gap: 10px;
    :global(.mdc-select__selected-text),
    :global(.mdc-deprecated-list-item) {
      font-size: 14px;
    }
  }
  .inputDesiredDate {
    box-sizing: border-box;
    width: 449px;
    height: 2.6em;
    padding: 0 12px;
    border: 1px solid #999;
    border-radius: 3px;
  }
  .deliveryTimeSelect {
    .deliveryTimeLabel {
      display: inline-flex;
      align-items: center;
      position: relative;
      width: 213px;
      &::after {
        position: absolute;
        right: 19px;
        width: 10px;
        height: 7px;
        background-color: #666;
        clip-path: polygon(0 0, 100% 0, 50% 76%);
        content: "";
        pointer-events: none;
      }
      select {
        appearance: none;
        width: 100%;
        height: 2.6em;
        padding: 0.4em 30px 0.4em 0.8em;
        border: 1px solid #999;
        border-radius: 3px;
        background-color: #fff;
        color: #333333;
        font-size: 1em;
        cursor: pointer;
      }
      select:hover {
        border-color: #333;
      }
    }
    .deliveryTime {
      height: 40px;
    }
  }
  .desiredTimeCheckbox {
    margin-left: 24px;
  }
  .dateAndTimeSelectArea {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 8px 0;
    :global(.mdc-select .mdc-select__menu) {
      max-height: 130px !important;
    }
  }
</style>
