<script>
  import { createEventDispatcher } from "svelte";

  import Troubles from "~/pages/Search/Update/EditableFields/Troubles.svelte";

  /** @type {import("~/libs/commonTypes").DetailedShipment} */
  export let shipment;

  let dispatch = createEventDispatcher();

  /**
   * 入力内容が変更されたとき、SearchResultUpdatePattern.svelteにイベントを発行する。
   * @param {CustomEvent} event
   */
  function onInputChange(event) {
    /** @type {boolean} 登録に必要な条件が全てそろっているか判定するフラグ */
    let isAllConditionsMet = false;
    isAllConditionsMet = event.detail.isAllConditionsMet;

    /** @type {import("~/libs/backendApi").UpdateShipmentEvent} 更新する荷物情報 */
    const updateShipmentEvent = {
      trackingNumber: shipment.trackingNumber,
      status: shipment.status,
      version: shipment.version,
    };
    updateShipmentEvent.extraEvent =
      event.detail.updateShipmentEvent.extraEvent;

    // イベントを発行
    dispatch("inputChange", {
      isAllConditionsMet,
      updateShipmentEvent,
    });
  }
</script>

<div class="wrapper">
  <Troubles {shipment} on:inputChange={onInputChange} />
</div>
