<script>
  import Checkbox from "@smui/checkbox";
  import FormField from "@smui/form-field";
  import Radio from "@smui/radio";
  import { createEventDispatcher } from "svelte";
  import { _ } from "svelte-i18n";

  import { RETURN_STATUS_REQUESTING, ReturnReason } from "~/libs/constants";

  /** @type {import("~/libs/commonTypes").DetailedShipment} */
  export let shipment;

  let dispatch = createEventDispatcher();

  /** @type {Array<ReturnReason>} 返品理由リスト */
  let returnReasonList = [
    ReturnReason.REQUESTED_RETURN_FROM_EC,
    ReturnReason.REQUESTED_CANCEL_FROM_RECEIVER,
  ];
  /** @type {boolean} 配達キャンセル登録を行うことの了承チェック */
  let agreeCancelChecked = false;
  /** @type { 0 | 1 } ユーザーによって選択された返品理由の区分値 */
  let selectedReturnReason;

  /**
   * 入力内容が変更されたとき、SearchResultUpdatePattern.svelteにイベントを発行する。
   */
  function onInputChange() {
    /** @type {boolean} 登録に必要な条件が全てそろっているか判定するフラグ */
    let isAllConditionsMet = false;
    if (agreeCancelChecked && selectedReturnReason !== undefined) {
      // 了承チェックと返品理由の選択がされていれば、登録に必要な条件が全てそろっていると判定
      isAllConditionsMet = true;
    }

    /** @type {import("~/libs/backendApi").UpdateShipmentEvent} 更新する荷物情報 */
    const updateShipmentEvent = {
      trackingNumber: shipment.trackingNumber,
      status: shipment.status,
      version: shipment.version,
    };
    updateShipmentEvent.returnStatus = RETURN_STATUS_REQUESTING;
    updateShipmentEvent.returnReason = selectedReturnReason;

    // イベントを発行
    dispatch("inputChange", {
      isAllConditionsMet,
      updateShipmentEvent,
    });
  }
</script>

<div class="wrapper">
  <div class="noteArea">
    <p>
      配達の中断と返品の手配を行うことができます。<br />
      キャンセル後の取り消しや、配達のキャンセルを行ったお荷物を再度お客様に配達することは<br
      />出来かねますので、予めご了承ください。
    </p>
    {#if shipment?.status === 3}
      <div class="alertMessage">
        <span class="material-icons .md-18"> warning_amber </span>
        <p>
          該当のお荷物は宅配ドライバーによって持ち出されているため、入れ違いで配達してしまう可能性がございます。<br
          />
          その場合、お荷物の回収は出来かねますので、お客様に直接ご連絡ください。
        </p>
      </div>
    {/if}
    {#if shipment?.numberOfPackages > 1}
      <!-- 複数個口の場合 -->
      <div class="alertMessage">
        <span class="material-icons .md-18"> warning_amber </span>
        <p>
          複数口（全{shipment.numberOfPackages}個）まとめてのキャンセルとなります。
        </p>
      </div>
    {/if}
    <FormField>
      <Checkbox bind:checked={agreeCancelChecked} on:change={onInputChange} />
      <span slot="label">同意して配達のキャンセルを行う</span>
    </FormField>
  </div>
  {#if agreeCancelChecked}
    <div class="cancelReasonArea">
      <span>キャンセルの理由を選択してください</span>
      {#each returnReasonList as returnReason}
        <FormField>
          <Radio
            bind:group={selectedReturnReason}
            value={returnReason}
            on:change={onInputChange}
          />
          <span slot="label">
            {$_(`classes.returnReasonByEc.${returnReason}`)}
            {#if returnReason === ReturnReason.REQUESTED_RETURN_FROM_EC}
              <span class="example">
                {$_(`classes.returnReasonExampleByEc.${returnReason}`)}
              </span>
            {/if}
          </span>
        </FormField>
      {/each}
    </div>
  {/if}
</div>

<style lang="scss">
  .noteArea {
    color: rgba(0, 0, 0, 0.7);
    .alertMessage {
      margin: 6px 0;
      padding: 8px 4px 8px 16px;
      color: #672b2a;
      background-color: #ffe7e7;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 10px;
      line-height: 1.4em;
    }
    .material-icons {
      font-size: 22px;
      color: #d74141;
    }
    :global(.mdc-form-field) {
      margin-top: 8px;
      margin-left: 6px;
    }
  }
  .cancelReasonArea {
    position: relative;
    margin: 20px 0 10px;
    padding: 8px 4px;
    border: solid 2px #018786;
    border-radius: 4px;
    > span {
      position: absolute;
      display: inline-block;
      top: -10px;
      left: 12px;
      padding: 0 16px;
      line-height: 1;
      font-size: 15px;
      background: #f8f8f8;
      color: #018786;
      font-weight: bolder;
    }
    .example {
      display: inline-block;
      margin-top: 2px;
      margin-left: 5px;
      font-size: 12px;
      color: var(--mdc-theme-secondary);
    }
    :global(.mdc-form-field) {
      width: 100%;
    }
  }
</style>
