<script>
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  export let filterValue;
  export let values;
</script>

<div class="text-filter-area">
  <input
    type="text"
    bind:value={$filterValue}
    on:click={(event) => {
      event.stopPropagation();
    }}
    on:input={() => {
      dispatch("filter", { value: $filterValue });
    }}
    placeholder="全て"
  />
  {#if $filterValue}
    <p>({$values.length.toLocaleString()})</p>
  {/if}
</div>

<style lang="scss">
  .text-filter-area {
    display: flex;
    align-items: center;

    input {
      font-size: 12px;
      height: 20px;
      border: 1px solid #ccc;
      border-radius: 5px;
      background-color: #fff;
    }

    p {
      font-size: 12px;
      margin-left: 5px;
    }
  }
</style>
