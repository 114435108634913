<script>
  import imageCompression from "browser-image-compression";
  import { createEventDispatcher } from "svelte";

  let dispatch = createEventDispatcher();

  /** @type {HTMLInputElement} 配達票写真の入力項目（ファイル選択） */
  let inputsignaturePhoto;
  let unattendedDeliveryPhotoErrorDisplay = false;

  /**
   * 登録ボタンを有効化するかどうかを判定する
   * @returns {boolean} 登録ボタンを有効化するかどうか
   */
  function updateRegisterButtonState() {
    /** @type {boolean} 登録に必要な条件が全てそろっているか判定するフラグ */
    let isAllConditionsMet = false;
    if (inputsignaturePhoto) {
      // 登録ボタンを有効化
      isAllConditionsMet = true;
    }
    return isAllConditionsMet;
  }

  /**
   * 更新する荷物情報を作成する
   * @returns {Promise<import("~/libs/backendApi").UpdateShipmentEvent>} 更新する荷物情報
   */
  async function createUpdateShipmentEvent() {
    /** @type {import("~/libs/backendApi").UpdateShipmentEvent} 更新する荷物情報 */
    const updateShipmentEvent = {};

    if (inputsignaturePhoto?.files?.length > 0) {
      // 配達票写真が選択されている場合
      let compressedPhoto = await imageCompression.getDataUrlFromFile(
        await imageCompression(inputsignaturePhoto.files[0], {
          maxSizeMB: 2,
          maxWidthOrHeight: 500,
          useWebWorker: true,
        }),
      );
      updateShipmentEvent.signaturePhoto = compressedPhoto.substring(
        compressedPhoto.indexOf("base64,") + 7,
      );
      compressedPhoto = undefined;
    }

    return updateShipmentEvent;
  }

  /**
   * 入力内容が変更されたとき、親コンポーネントにイベントを発行する。
   */
  async function onInputChange() {
    const updateShipmentEvent = await createUpdateShipmentEvent();
    // イベントを発行
    dispatch("inputChange", {
      updateFieldName: "signaturePhoto",
      isAllConditionsMet: updateRegisterButtonState(),
      updateShipmentEvent: updateShipmentEvent,
    });
  }
</script>

<div class="item">
  <div class="itemTh">配達票写真</div>
  <div class="itemTd">
    <input
      bind:this={inputsignaturePhoto}
      on:change={onInputChange}
      type="file"
      accept="image/png, image/jpeg"
    />
    {#if unattendedDeliveryPhotoErrorDisplay}
      <p class="errorNote">
        アップロードできないファイル形式です。JPEG形式のファイルをアップロードしてください。
      </p>
      <p class="errorNote">
        スマホでご利用の場合、最新版のOS、ブラウザにアップデートのうえご利用ください。<br
        />
        なお、Androidの場合、ブラウザはChromeをご利用ください。
      </p>
    {/if}
  </div>
</div>

<style lang="scss">
  .item {
    width: 650px;
    display: flex;
    gap: 10px;
    position: relative;
    padding: 6px 0;
    border-bottom: 1px solid #eee;
  }
  .itemTh {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    padding: 10px 0;
    min-width: 120px;
    line-height: 1.2em;
    background-color: #b4d0f1cb;
    color: #242424;
    font-size: smaller;
    font-weight: 900;
    text-align: center;
  }
  .itemTd {
    display: flex;
    flex-grow: 1;
    align-items: center;
    gap: 10px;
    :global(.mdc-select__selected-text),
    :global(.mdc-deprecated-list-item) {
      font-size: 14px;
    }
  }
</style>
