<script>
  import { createEventDispatcher } from "svelte";
  import { _ } from "svelte-i18n";

  import { STATUS_DELIVERED } from "~/libs/constants";
  import ActualPackageDropPlace from "~/pages/Search/Update/EditableFields/ActualPackageDropPlace.svelte";
  import SignaturePhoto from "~/pages/Search/Update/EditableFields/SignaturePhoto.svelte";
  import UnattendedDeliveryPhoto from "~/pages/Search/Update/EditableFields/UnattendedDeliveryPhoto.svelte";

  /** @type {import("~/libs/commonTypes").DetailedShipment} */
  export let shipment;

  let dispatch = createEventDispatcher();

  /** @type {boolean} 置き配写真を表示するかどうかを示すフラグ*/
  let displayUnattendedDeliveryPhoto = false;

  /** @type {boolean} 配達票写真を表示するかどうかを示すフラグ*/
  let displaysignaturePhoto = false;

  /** @type {object} 登録ボタンを有効化してよいか判断するための一覧 */
  let registerButtonState = {
    actualPackageDropPlace: null,
    unattendedDeliveryPhoto: null,
    signaturePhoto: null,
  };

  /** @type {object} 更新する荷物情報を保持する */
  let keptUpdateShipmentEvent = {};

  // ページの初期化処理
  (() => {
    judgeDisplayPhoto();
  })();

  /**
   * 置き配写真と配達票写真の出し分けを行う
   */
  function judgeDisplayPhoto() {
    if (shipment?.signatureRequired) {
      // 配達証明の種別が＜受領印もしくは署名＞の場合
      displaysignaturePhoto = true;
    } else {
      // 配達証明の種別が＜手渡し：なし、置き配：写真＞の場合
      if (shipment?.unattendedDeliveryPhotoUploaded) {
        // 置き配写真が登録されている場合
        displayUnattendedDeliveryPhoto = true;
      } else if (shipment?.signaturePhotoUploaded) {
        // 配達票写真が登録されている場合
        displaysignaturePhoto = true;
      } else {
        // 置き配写真も配達票写真も登録されていない場合
        displayUnattendedDeliveryPhoto = true;
      }
    }
  }

  /**
   * 入力内容が変更されたとき、SearchResultUpdatePattern.svelteにイベントを発行する。
   * @param {CustomEvent} event
   */
  async function onInputChange(event) {
    /** @type {boolean} 登録に必要な条件が全てそろっているか判定するフラグ */
    let isAllConditionsMet = false;

    registerButtonState[event.detail.updateFieldName] =
      event.detail.isAllConditionsMet;

    for (const key in registerButtonState) {
      if (registerButtonState[key] === false) {
        // 未入力の項目がある場合は登録ボタンを無効化
        isAllConditionsMet = false;
      }
    }
    isAllConditionsMet = true;

    // 更新する荷物情報を作成
    /** @type {import("~/libs/backendApi").UpdateShipmentEvent} 更新する荷物情報 */
    const updateShipmentEvent = {
      trackingNumber: shipment.trackingNumber,
      status: shipment.status,
      version: shipment.version,
    };
    if (keptUpdateShipmentEvent) {
      // 保持している更新情報がある場合は、それを引き継ぐ
      Object.assign(updateShipmentEvent, keptUpdateShipmentEvent);
    }
    /** @type {Array<"photo" | "returnStatus" | "returnReason">} 初期値対象リスト */
    const initializeFields = [];

    // 配送ステータスを配達完了に変更
    updateShipmentEvent.status = STATUS_DELIVERED;
    if (shipment.returnStatus !== undefined) {
      // 返品ステータスが設定されている場合
      initializeFields.push("returnStatus");
      initializeFields.push("returnReason");
    }
    if (event.detail.updateFieldName === "actualPackageDropPlace") {
      // 実際の配達方法が変更された場合
      judgeDisplayPhoto();
      updateShipmentEvent.actualPackageDropPlace =
        event.detail.updateShipmentEvent.actualPackageDropPlace;
    } else if (
      displayUnattendedDeliveryPhoto &&
      event.detail.updateFieldName === "unattendedDeliveryPhoto"
    ) {
      // 置き配写真が選択された場合
      updateShipmentEvent.unattendedDeliveryPhoto =
        event.detail.updateShipmentEvent.unattendedDeliveryPhoto;
    } else if (event.detail.updateFieldName === "signaturePhoto") {
      // 配達票写真が選択されている場合
      updateShipmentEvent.signaturePhoto =
        event.detail.updateShipmentEvent.signaturePhoto;
    }
    if (initializeFields.length > 0) {
      // 初期化する値がある場合
      updateShipmentEvent.initializeFields = initializeFields;
    }

    keptUpdateShipmentEvent = updateShipmentEvent;
    // イベントを発行
    dispatch("inputChange", {
      isAllConditionsMet,
      updateShipmentEvent,
    });
  }
</script>

<div class="wrapper">
  <div class="item" id="status">
    <div class="itemTh">配送／返品ステータス</div>
    <div class="itemTd">
      <div>
        <p>
          {#if shipment.status != STATUS_DELIVERED}
            <span class="oldStatus"
              >{$_(`classes.status.${shipment.status}`)}</span
            >→
          {/if}
          <span class="newStatus">配達完了</span>
        </p>
        {#if shipment.returnStatus}
          <div class="alertMessage">
            <span class="material-icons .md-18"> warning_amber </span>
            <p>返品ステータス／返品理由が削除され、配達完了に変更されます。</p>
          </div>
        {/if}
      </div>
    </div>
  </div>

  <ActualPackageDropPlace {shipment} on:inputChange={onInputChange} />

  {#if displayUnattendedDeliveryPhoto}
    <UnattendedDeliveryPhoto on:inputChange={onInputChange} />
  {/if}

  {#if displaysignaturePhoto}
    <SignaturePhoto on:inputChange={onInputChange} />
  {/if}
</div>

<style lang="scss">
  .item {
    display: flex;
    gap: 10px;
    position: relative;
    padding: 6px 0;
    border-bottom: 1px solid #eee;
    width: 635px;
  }
  .itemTh {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    padding: 10px 0;
    min-width: 120px;
    line-height: 1.2em;
    background-color: #b4d0f1cb;
    color: #242424;
    font-size: smaller;
    font-weight: 900;
    text-align: center;
  }
  .itemTd {
    display: flex;
    flex-grow: 1;
    align-items: center;
    gap: 10px;
    :global(.mdc-select__selected-text),
    :global(.mdc-deprecated-list-item) {
      font-size: 14px;
    }
  }
  .oldStatus {
    margin-right: 2px;
    text-decoration: line-through;
  }
  .newStatus {
    margin-left: 2px;
    font-weight: bolder;
  }
  .alertMessage {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 4px;
    margin: 6px 0;
    padding: 8px 0 8px 8px;
    color: #672b2a;
    background-color: #ffe7e7;
    border-radius: 4px;
    line-height: 1.4em;
    .material-icons {
      font-size: 22px;
      color: #d74141;
    }
  }
</style>
