<script>
  import { createEventDispatcher } from "svelte";

  let dispatch = createEventDispatcher();

  /** @type {string} 訂正後の荷受人の住所 */
  let changedCorrectedReceiverAddress;

  /**
   * 登録ボタンを有効化するかどうかを判定する
   * @returns {boolean} 登録ボタンを有効化するかどうか
   */
  function updateRegisterButtonState() {
    /** @type {boolean} 登録に必要な条件が全てそろっているか判定するフラグ */
    let isAllConditionsMet = false;
    if (changedCorrectedReceiverAddress?.length > 0) {
      // 登録ボタンを有効化
      isAllConditionsMet = true;
    }
    return isAllConditionsMet;
  }

  /**
   * 更新する荷物情報を作成する
   * @returns {Promise<import("~/libs/backendApi").UpdateShipmentEvent>} 更新する荷物情報
   */
  async function createUpdateShipmentEvent() {
    /** @type {import("~/libs/backendApi").UpdateShipmentEvent} 更新する荷物情報 */
    const updateShipmentEvent = {};

    if (changedCorrectedReceiverAddress?.length > 0) {
      // 荷受人の住所が変更された場合
      updateShipmentEvent.correctedReceiverAddress =
        changedCorrectedReceiverAddress;
    }
    return updateShipmentEvent;
  }

  /**
   * 入力内容が変更されたとき、親コンポーネントにイベントを発行する。
   */
  async function onInputChange() {
    const updateShipmentEvent = await createUpdateShipmentEvent();
    // イベントを発行
    dispatch("inputChange", {
      updateFieldName: "correctedReceiverAddress",
      isAllConditionsMet: updateRegisterButtonState(),
      updateShipmentEvent: updateShipmentEvent,
    });
  }
</script>

<div class="item">
  <div class="itemTh">荷受人の住所 (訂正後)</div>
  <div class="itemTd">
    <textarea
      id="correctedReceiverAddressChange"
      wrap="soft"
      maxlength="20000"
      bind:value={changedCorrectedReceiverAddress}
      on:input={onInputChange}
    />
  </div>
</div>

<style lang="scss">
  .item {
    width: 650px;
    display: flex;
    gap: 10px;
    position: relative;
    padding: 6px 0;
    border-bottom: 1px solid #eee;
  }
  .itemTh {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    padding: 10px 0;
    min-width: 120px;
    line-height: 1.2em;
    background-color: #b4d0f1cb;
    color: #242424;
    font-size: smaller;
    font-weight: 900;
    text-align: center;
  }
  .itemTd {
    display: flex;
    flex-grow: 1;
    align-items: center;
    gap: 10px;
    :global(.mdc-select__selected-text),
    :global(.mdc-deprecated-list-item) {
      font-size: 14px;
    }
  }
  #correctedReceiverAddressChange {
    width: 478px;
    height: 30px;
    padding: 4px;
  }
</style>
