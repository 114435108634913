<script>
  import Button from "@smui/button";
  import { createEventDispatcher } from "svelte";
  import { _ } from "svelte-i18n";

  import { TROUBLE_LIST } from "~/libs/constants";
  import { formatStringDate } from "~/libs/utils";

  /** @type {import("~/libs/commonTypes").DetailedShipment} */
  export let shipment;

  let dispatch = createEventDispatcher();

  /** @type {Array<import("~/libs/commonTypes").ExtraEvent>} */
  let extraEvents;
  let changedTroubles = [];

  // ページの初期化処理（非同期）
  (async () => {
    extraEvents = shipment.extraEvent;
    if (extraEvents) {
      extraEvents.forEach((extraEvent) => {
        let changedTrouble = [];
        changedTrouble["time"] = extraEvent.time;
        changedTrouble["troubleKbn"] = extraEvent.extraEventType;
        changedTroubles.push(changedTrouble);
      });
    }
  })();

  /**
   * 発生事象を追加するボタンを押下したときの処理
   */
  function handleAddButtonClick() {
    AddTroubleString();
    onInputChange();
  }

  const AddTroubleString = async () => {
    let newTrouble = {};
    newTrouble["time"] = interfaceDateFormat(new Date());
    newTrouble["troubleKbn"] = "";
    changedTroubles.push(newTrouble);
    changedTroubles = changedTroubles;
  };

  function interfaceDateFormat(date) {
    let year = date.getFullYear().toString();
    let month = ("0" + (1 + date.getMonth()).toString()).slice(-2);
    let day = ("0" + date.getDate().toString()).slice(-2);
    let hour = ("0" + date.getHours().toString()).slice(-2);
    let minute = ("0" + date.getMinutes().toString()).slice(-2);

    let formatStr = "YYYY-MM-DD hh:mm:00";
    formatStr = formatStr.replace(/YYYY/g, year);
    formatStr = formatStr.replace(/MM/g, month);
    formatStr = formatStr.replace(/DD/g, day);
    formatStr = formatStr.replace(/hh/g, hour);
    formatStr = formatStr.replace(/mm/g, minute);

    return formatStr;
  }

  /**
   * 登録ボタンを有効化するかどうかを判定する
   * @returns {boolean} 登録ボタンを有効化するかどうか
   */
  function updateRegisterButtonState() {
    /** @type {boolean} 登録に必要な条件が全てそろっているか判定するフラグ */
    let isAllConditionsMet = false;

    if (shipment.extraEvent != changedTroubles) {
      // 発生事象が変更されている場合
      for (let i = 0; i < changedTroubles.length; i++) {
        if (changedTroubles[i].troubleKbn === "") {
          break;
        }
        if (i === changedTroubles.length - 1) {
          // すべての発生事象区分が選択されていれば、登録に必要な条件が全てそろっていると判定
          isAllConditionsMet = true;
        }
      }
    }

    return isAllConditionsMet;
  }

  /**
   * 更新する荷物情報を作成する
   * @returns {Promise<import("~/libs/backendApi").UpdateShipmentEvent>} 更新する荷物情報
   */
  async function createUpdateShipmentEvent() {
    /** @type {import("~/libs/backendApi").UpdateShipmentEvent} 更新する荷物情報 */
    const updateShipmentEvent = {};

    if (changedTroubles !== shipment.extraEvent) {
      updateShipmentEvent.extraEvent = changedTroubles.map(
        (changedTrouble) =>
          /** @type {import("~/libs/commonTypes").ExtraEvent} */
          ({
            time: changedTrouble.time,
            extraEventType: changedTrouble.troubleKbn,
          }),
      );
    }

    return updateShipmentEvent;
  }

  /**
   * 入力内容が変更されたとき、親コンポーネントにイベントを発行する。
   */
  async function onInputChange() {
    const updateShipmentEvent = await createUpdateShipmentEvent();
    // イベントを発行
    dispatch("inputChange", {
      updateFieldName: "troubles",
      isAllConditionsMet: updateRegisterButtonState(),
      updateShipmentEvent: updateShipmentEvent,
    });
  }
</script>

<div class="item">
  <div class="itemTh">配送中の発生事象</div>
  <div class="itemTd">
    <div class="itemTdInner">
      {#each changedTroubles as extraEvent, index}
        <p style:margin-top={index == 0 ? "0" : "8px"}>
          {formatStringDate(extraEvent.time, "yyyy/MM/dd HH:mm")}
        </p>
        <!-- 配送中の発生事象 -->
        <div class="troubleLine">
          <p>区分：</p>
          <label for="troubleKbn" class="troubleKbnLabel">
            <select
              name="troubleKbn"
              id="troubleKbn"
              bind:value={extraEvent.troubleKbn}
              on:change={onInputChange}
            >
              <option value="" disabled>選択してください</option>
              {#each TROUBLE_LIST as troubleKbn}
                <option value={troubleKbn}
                  >{$_(
                    `pages.Search.extraEventTypeLabel.${troubleKbn}`,
                  )}</option
                >
              {/each}
            </select>
          </label>
        </div>
      {/each}
      {#if changedTroubles.length == 0}
        <p>未登録</p>
      {/if}
      <div
        class="buttonArea"
        style:margin-top={changedTroubles.length == 0 ? "2px" : "14px"}
      >
        <Button touch variant="unelevated" on:click={handleAddButtonClick}
          >＋発生事象を追加する</Button
        >
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  .item {
    display: flex;
    gap: 10px;
    position: relative;
    padding: 6px 0;
    border-bottom: 1px solid #eee;
    width: 635px;
  }
  .itemTh {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    padding: 10px 0;
    min-width: 120px;
    line-height: 1.2em;
    background-color: #b4d0f1cb;
    color: #242424;
    font-size: smaller;
    font-weight: 900;
    text-align: center;
  }
  .itemTd {
    display: flex;
    flex-grow: 1;
    align-items: center;
    gap: 10px;
    :global(.mdc-select__selected-text),
    :global(.mdc-deprecated-list-item) {
      font-size: 14px;
    }
  }
  .itemTdInner {
    width: 400px;
    flex-grow: 1;
    display: flex;
    color: #333;
    font-size: 13px;
    flex-direction: column;
    .troubleLine {
      display: flex;
    }
    .troubleLine {
      .troubleKbnLabel {
        display: inline-flex;
        align-items: center;
        position: relative;
        width: 200px;
        &::after {
          position: absolute;
          right: 12px;
          width: 10px;
          height: 7px;
          background-color: #666;
          clip-path: polygon(0 0, 100% 0, 50% 76%);
          content: "";
          pointer-events: none;
        }
        select {
          appearance: none;
          width: 100%;
          height: 2em;
          padding: 0.2em 30px 0.2em 0.6em;
          border: 1px solid #999;
          border-radius: 3px;
          background-color: #fff;
          color: #333333;
          font-size: 1em;
          cursor: pointer;
        }
        select:hover {
          border-color: #333;
        }
      }
    }
    :global(.mdc-button) {
      width: 240px;
      height: 26px;
      font-size: 14px;
      padding: 0;
    }
  }
</style>
