<script>
  import Button from "@smui/button";
  import { getContext, onDestroy } from "svelte";
  import {
    Render,
    Subscribe,
    createRender,
    createTable,
  } from "svelte-headless-table";
  import { addColumnFilters, addSortBy } from "svelte-headless-table/plugins";

  import MessageDialog from "~/components/MessageDialog.svelte";
  import TextFilter from "~/components/TextFilter.svelte";
  import { CONTEXT_KEY_USER } from "~/libs/constants";
  import { textIncludeFilter } from "~/libs/filters";
  import {
    editManagementResult,
    managementResultData,
    managementResultEditClose,
    messageDialogClose,
  } from "~/libs/stores";
  import ManagementResultEdit from "~/pages/Management/ManagementResultEdit.svelte";

  /**
   * @type {Array<import("~/libs/commonTypes").CustomedUserInfo>}
   */
  export let results;

  /** @type {import("~/libs/backendApi").GetCompaniesResponse}>} */
  export let companyNameList;

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** @type {import("svelte/store").Unsubscriber} */
  let managementResultEditCloseUnsubscriber;

  /** @type {import("svelte/store").Unsubscriber} */
  let messageDialogCloseUnsubscriber;

  let managementResultEdit;
  let target;
  let displayingDetails = false;
  let changeButtonDisabled = userContext.hasPermitUserManagementRole()
    ? false
    : true;
  let dialogComponent;
  let dialogTitle;
  let dialogMessage;
  let result;

  (() => {
    managementResultData.set(results);
  })();

  const table = createTable(managementResultData, {
    sort: addSortBy({ toggleOrder: ["asc", "desc"] }),
    filter: addColumnFilters(),
  });

  const columns = table.createColumns(
    (() => {
      const columns = [
        table.column({
          header: "",
          id: "updateButton",
          accessor: (item) => item,
          plugins: {
            sort: { disable: true },
          },
        }),
        table.column({
          header: "ユーザーID",
          id: "userName",
          accessor: (item) => item.userName ?? "",
        }),
        table.column({
          header: "表示名",
          id: "displayName",
          accessor: (item) => item.displayName ?? "",
          plugins: {
            filter: {
              fn: textIncludeFilter,
              initialFilterValue: "",
              render: ({ filterValue, values }) =>
                createRender(TextFilter, {
                  filterValue,
                  values,
                }),
            },
          },
        }),
        table.column({
          header: "メールアドレス",
          id: "emailAddress",
          accessor: (item) => item.emailAddress ?? "",
        }),
        table.column({
          header: "管理者専用メモ",
          id: "contactInfo",
          accessor: (item) => item.contactInfo ?? "",
        }),
        table.column({
          header: "アカウント状態",
          id: "disabled",
          accessor: (item) => {
            if (item.disabled === true) {
              return "無効";
            } else if (item.disabled === false) {
              return "";
            } else {
              return "";
            }
          },
        }),
      ];
      return columns;
    })(),
  );

  managementResultEditCloseUnsubscriber = managementResultEditClose.subscribe(
    async (isClosed) => {
      if (displayingDetails && isClosed) {
        if ($editManagementResult) {
          await updatedResultsReplace($editManagementResult);
          editManagementResult.set(null);
        }
        displayingDetails = false;
        managementResultEdit = null;
        managementResultEditClose.set(false);
        if (result == "success" || result == "failed") {
          result = null;
          dialogComponent = MessageDialog;
        }
      }
    },
  );

  messageDialogCloseUnsubscriber = messageDialogClose.subscribe(() => {
    dialogTitle = null;
    dialogMessage = null;
    dialogComponent = null;
    messageDialogClose.set(false);
  });

  async function openWindow(result) {
    target = result;
    displayingDetails = true;
    managementResultEdit = null;
    managementResultEdit = await ManagementResultEdit;
  }

  function handleMessage(event) {
    result = event.detail.result;
    dialogTitle = event.detail.title;
    dialogMessage = event.detail.message;
  }

  /**
   * 検索結果一覧に更新内容を反映する。
   * @param {import("~/libs/backendApi").UserInfo} updatedResult
   */
  async function updatedResultsReplace(updatedResult) {
    let targetIndex;
    for (targetIndex = 0; targetIndex <= results.length; targetIndex++) {
      if (results[targetIndex].id === updatedResult.id) {
        break;
      }
    }
    results.splice(targetIndex, 1, updatedResult);
    results = results;
    managementResultData.set(results);
  }

  onDestroy(() => {
    managementResultEditCloseUnsubscriber?.();
    messageDialogCloseUnsubscriber?.();
  });

  const { headerRows, rows, tableAttrs, tableHeadAttrs, tableBodyAttrs } =
    table.createViewModel(columns);
</script>

<svelte:component this={dialogComponent} {dialogTitle} {dialogMessage} />
<svelte:component
  this={managementResultEdit}
  result={target}
  on:message={handleMessage}
  {companyNameList}
/>
<div class="mdc-data-table">
  <div class="mdc-data-table__table-container">
    <table class="mdc-data-table__table" {...$tableAttrs}>
      <thead {...$tableHeadAttrs}>
        {#each $headerRows as headerRow (headerRow.id)}
          <Subscribe rowAttrs={headerRow.attrs()} let:rowAttrs>
            <tr class="mdc-data-table__header-row" {...rowAttrs}>
              {#each headerRow.cells as cell (cell.id)}
                <Subscribe
                  attrs={cell.attrs()}
                  let:attrs
                  props={cell.props()}
                  let:props
                >
                  <th
                    class="mdc-data-table__header-cell"
                    {...attrs}
                    style={cell.id === "updateButton" ? "" : "cursor: pointer;"}
                    on:click={props.sort.toggle}
                  >
                    <div class="th-item">
                      <Render of={cell.render()} />
                      {#if props.sort.order === "asc"}
                        <span class="material-icons">arrow_upward</span>
                      {:else if props.sort.order === "desc"}
                        <span class="material-icons">arrow_downward</span>
                      {/if}
                    </div>
                    {#if props.filter?.render}
                      <div class="filter-area">
                        <Render of={props.filter.render} />
                      </div>
                    {/if}
                  </th>
                </Subscribe>
              {/each}
            </tr>
          </Subscribe>
        {/each}
      </thead>
      <tbody class="mdc-data-table__content" {...$tableBodyAttrs}>
        {#if $rows.length === 0}
          <tr class="mdc-data-table__row">
            <td class="mdc-data-table__cell no_data_note" colspan="6">
              該当するデータがありません。
            </td>
          </tr>
        {:else}
          {#each $rows as row (row.id)}
            <Subscribe rowAttrs={row.attrs()} let:rowAttrs>
              <tr class="mdc-data-table__row" {...rowAttrs}>
                {#each row.cells as cell (cell.id)}
                  <Subscribe attrs={cell.attrs()} let:attrs>
                    {#if cell.id === "updateButton"}
                      <td class="mdc-data-table__cell" {...attrs}>
                        <Button
                          style="height: 30px; width: 60px; margin-left: 10px;"
                          touch
                          variant="unelevated"
                          on:click={() => {
                            openWindow(cell.value);
                          }}
                          bind:disabled={changeButtonDisabled}>変更</Button
                        >
                      </td>
                    {:else if cell.id === "disabled"}
                      <td
                        class="mdc-data-table__cell"
                        {...attrs}
                        style="color: #F90404;"
                      >
                        <Render of={cell.render()} />
                      </td>
                    {:else if cell.id === "contactInfo"}
                      <td
                        class="mdc-data-table__cell contact-info-cell"
                        {...attrs}
                      >
                        <Render of={cell.render()} />
                      </td>
                    {:else}
                      <td class="mdc-data-table__cell" {...attrs}>
                        <Render of={cell.render()} />
                      </td>
                    {/if}
                  </Subscribe>
                {/each}
              </tr>
            </Subscribe>
          {/each}
        {/if}
      </tbody>
    </table>
  </div>
</div>

<style lang="scss">
  .wrapper {
    width: calc(100% - 40px);
    padding: 20px 20px 20px 20px;
    flex-direction: column;
  }

  .titleArea {
    height: 40px;
    display: flex;
  }

  .titleLine {
    background-color: #064491cb;
    min-width: 15px;
    height: 40px;
    border-radius: 0px 0px 0px 0px;
  }

  .title {
    display: flex;
    font-size: x-large;
    margin: auto auto auto 10px;
    min-width: 500px;
  }

  .captionArea {
    background-color: #064491cb;
    width: 200px;
    height: 26px;
    border-radius: 10px 10px 0px 0px;
    padding-top: 12px;
    padding-left: 20px;
    margin-top: 16px;
    color: #fff;
    font-weight: 900;
  }

  .resultArea {
    width: 96%;
    min-width: 822px;
    height: fit-content;
    background-color: white;
    border: 1.5px solid #bdbdbdcb;
    border-radius: 0px 5px 5px 5px;
    padding: 5px 5px 5px 5px;
    display: columns;
  }

  #progressBar {
    margin: 10px;
  }

  #noDataComment {
    margin: 10px;
    font-size: smaller;
  }

  .resultHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }

  .resultNumber {
    margin: 12px 20px 5px;
  }

  .mdc-data-table {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    max-height: calc(100vh - 236px);
    overflow-x: auto;
    overflow-y: none;
  }

  .mdc-data-table__table thead {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .contact-info-cell {
    min-width: 200px;
    max-width: 420px;
    padding: 8px 16px;
    white-space: break-spaces;
    overflow-wrap: break-word;
  }

  th {
    background-color: #eaf5ff;
  }

  th {
    vertical-align: middle;
    font-size: small;

    .th-item {
      display: flex;
      position: relative;

      span {
        position: relative;
        margin-left: 3px;
        top: 3px;
        font-size: 18px;
        color: #5c5c5c;
      }
    }
  }

  td {
    vertical-align: middle;
    font-size: small;
  }

  tr th:nth-child(1),
  tr td:nth-child(1) {
    width: 1px;
    padding-left: 4px;
    padding-right: 0;
  }
</style>
