import {
  RecoveryResult,
  STATUS_CREATED,
  shippingAndReturnStatusType,
} from "~/libs/constants";

/**
 * 検索結果に表示制御用プロパティを追加する。
 * @param {Array<object>} results
 * @returns {Promise<Array<object>>}
 */
export async function addDisplayControlProperties(results) {
  return results.map(async (result) => {
    result.shippingAndReturnStatus = await setShippingAndReturnStatus(result);
    return result;
  });
}

/**
 * 表示制御用プロパティを返す。
 * - 1桁目：大ステータス（0:通常配送、1:出荷取消、2:返品、9:配達不能）
 * - 2桁目：中ステータス（通常配送の場合は配送ステータス、返品の場合は返品ステータス）
 * - 3桁目：小ステータス（返品の場合は返品理由）
 * - 4桁目：小ステータス（返品要求中の場合、未荷受けの場合は裏の配送ステータス）
 * @param {import("~/libs/backendApi").SearchedShipment} result
 * @returns {Promise<string>}
 */
export async function setShippingAndReturnStatus(result) {
  let shippingAndReturnStatus;
  if (result.lost) {
    //配達不能（紛失）
    shippingAndReturnStatus =
      shippingAndReturnStatusType.UNDELIVERABLE_WITH_LOST;
  } else if (result.misdeliveryState === RecoveryResult.FAILURE) {
    // 配達不能（誤送）
    shippingAndReturnStatus =
      shippingAndReturnStatusType.UNDELIVERABLE_WITH_MISDELIVERY;
  } else if (result.notActuallyReceived) {
    // 未荷受け
    shippingAndReturnStatus =
      shippingAndReturnStatusType.UNDELIVERABLE_WITH_NOT_ACTUALLY_RECEIVED +
      String(result.status);
  } else if (result.returnStatus != null) {
    // 返品
    if (result.status === STATUS_CREATED) {
      shippingAndReturnStatus = shippingAndReturnStatusType.CANCELLED;
    } else {
      shippingAndReturnStatus = "2";
      shippingAndReturnStatus +=
        String(result.returnStatus + 1) + String(result.returnReason ?? "0");
      if (result.returnStatus === 0) {
        shippingAndReturnStatus += String(result.status);
      }
    }
  } else {
    // その他
    shippingAndReturnStatus = "0" + String(result.status) + "0";
  }
  return shippingAndReturnStatus;
}
