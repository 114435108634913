<script>
  import { createEventDispatcher, getContext } from "svelte";
  import { _ } from "svelte-i18n";

  import {
    CONTEXT_KEY_USER,
    EditPattern,
    STATUS_OUT_FOR_DELIVERY,
  } from "~/libs/constants";

  /** @type {import("~/libs/commonTypes").DetailedShipment} */
  export let shipment;

  /** @type {import("~/libs/constants").EditPattern} ユーザーによって選択された編集内容 */
  export let changedEditPattern;

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  const dispatch = createEventDispatcher();

  /** @type {string} 対EC事業者の通信欄に入力された内容 */
  let changedEcDelivererInternalMessage;
  /** @type {string} 配送事業者内の通信欄に入力された内容 */
  let changedDelivererInternalMessage;
  /** @type {string} 宅配事業者管理者専用のメモ欄に入力された内容 */
  let changedShippingPartnerInternalMessage;

  // ページの初期化処理
  (() => {
    changedEcDelivererInternalMessage = shipment.ecDelivererInternalMessage;
    if (!userContext.hasEcAdminRole()) {
      changedDelivererInternalMessage = shipment.delivererInternalMessage;
    }
    if (userContext.hasShippingPartnerAdminRole()) {
      changedShippingPartnerInternalMessage =
        shipment.shippingPartnerInternalMessage;
    }
  })();

  /**
   * 入力内容が変更されたとき、SearchResultUpdate.svelteにイベントを発行する。
   */
  function handleInputChange() {
    updateRegisterButtonState();
    handleUpdateMemoEvent();
  }

  /**
   * 登録ボタンの有効化状態を更新する。
   */
  function updateRegisterButtonState() {
    /** @type {boolean} 登録ボタンの無効化フラグ */
    let registerButtonDisabled = true;

    if (
      changedEcDelivererInternalMessage !=
        shipment.ecDelivererInternalMessage ||
      changedDelivererInternalMessage != shipment.delivererInternalMessage ||
      changedShippingPartnerInternalMessage !=
        shipment.shippingPartnerInternalMessage
    ) {
      // 通信欄もしくは管理者専用メモ欄の入力値に変更があった場合、登録ボタンを有効化
      registerButtonDisabled = false;
    }

    dispatch("enableRegisterButton", {
      registerButtonDisabled: registerButtonDisabled,
    });
  }

  /**
   * メモの更新イベントを発行する。
   */
  function handleUpdateMemoEvent() {
    // 更新する荷物情報を作成
    /** @type {import("~/libs/backendApi").UpdateShipmentEvent} 更新する荷物情報 */
    const updateShipmentEvent = {};

    if (
      changedEcDelivererInternalMessage != shipment.ecDelivererInternalMessage
    ) {
      updateShipmentEvent.ecDelivererInternalMessage =
        changedEcDelivererInternalMessage;
      if (
        userContext.hasEcAdminRole() &&
        shipment.status === STATUS_OUT_FOR_DELIVERY &&
        shipment.driverId
      ) {
        // EC事業管理者による更新、かつ持出中の場合はドライバーに通知する
        const message = {
          title: $_(
            "pages.Search.pushNotificationMessage.title.delivererInternalMessage",
          ),
          body: $_("pages.Search.pushNotificationMessage.body", {
            values: { trackingNumber: shipment.trackingNumber },
          }),
        };
        updateShipmentEvent.pushNotification = {
          title: message.title,
          body: message.body,
          data: {
            message: message,
            trackingNumber: shipment.trackingNumber,
            ecDelivererInternalMessage: changedEcDelivererInternalMessage,
          },
        };
      }
    }
    if (!userContext.hasEcAdminRole()) {
      // EC事業者管理者以外の場合は配送事業者内の通信欄も更新
      if (
        changedDelivererInternalMessage != shipment.delivererInternalMessage
      ) {
        updateShipmentEvent.delivererInternalMessage =
          changedDelivererInternalMessage;
        if (shipment.status === STATUS_OUT_FOR_DELIVERY && shipment.driverId) {
          // 持出中の場合はドライバーに通知する
          if (!updateShipmentEvent.pushNotification) {
            // pushNotificationが未設定の場合は通知メッセージも含め設定
            const message = {
              title: $_(
                "pages.Search.pushNotificationMessage.title.delivererInternalMessage",
              ),
              body: $_("pages.Search.pushNotificationMessage.body", {
                values: { trackingNumber: shipment.trackingNumber },
              }),
            };
            updateShipmentEvent.pushNotification = {
              title: message.title,
              body: message.body,
              data: {
                message: message,
                trackingNumber: shipment.trackingNumber,
                delivererInternalMessage: changedDelivererInternalMessage,
              },
            };
          } else {
            // pushNotificationが設定済(EC通信欄も同時に変更あり)の場合は更新データのみ設定
            updateShipmentEvent.pushNotification.data.delivererInternalMessage =
              changedDelivererInternalMessage;
          }
        }
      }
    }
    if (userContext.hasShippingPartnerAdminRole()) {
      // 宅配事業者管理者の場合は管理者専用メモ欄も更新
      if (
        changedShippingPartnerInternalMessage !=
        shipment.shippingPartnerInternalMessage
      ) {
        updateShipmentEvent.shippingPartnerInternalMessage =
          changedShippingPartnerInternalMessage;
      }
    }

    dispatch("updateShipmentEvent", {
      updateShipmentEvent: updateShipmentEvent,
    });
  }
</script>

<div class="wrapper">
  <!-- 通信欄 (対EC事業者) -->
  {#if changedEditPattern !== EditPattern.UNRECEIVED}
    <div class="item">
      <div class="itemTh">
        通信欄{#if !userContext.hasEcAdminRole()}<br />(対EC事業者){/if}
      </div>
      <div class="itemTd">
        <div class="itemTdLeft column">
          <textarea
            id="communicationChange"
            wrap="soft"
            bind:value={changedEcDelivererInternalMessage}
            on:input={handleInputChange}
            style="width: 476px; height: 100px; padding: 4px;"
            maxlength="20000"
          />
          <p class="note">
            ※お客様へは公開されない、{userContext.hasEcAdminRole()
              ? "配送事業者"
              : "EC事業者"}との情報共有スペース
          </p>
        </div>
      </div>
    </div>
  {/if}

  <!-- 通信欄 (配送事業者内) -->
  {#if !userContext.hasEcAdminRole()}
    <div class="item">
      <div class="itemTh">通信欄<br />(配送事業者内)</div>
      <div class="itemTd">
        <div class="itemTdLeft column">
          <textarea
            id="communicationChange"
            wrap="soft"
            bind:value={changedDelivererInternalMessage}
            on:input={handleInputChange}
            style="width: 476px; height: 100px; padding: 4px;"
            maxlength="20000"
          />
          <p class="note">
            ※お客様とEC事業者へは公開されない、配送事業者内(幹線輸送事業者と宅配事業者)の情報共有スペース
          </p>
        </div>
      </div>
    </div>
  {/if}

  {#if userContext.hasShippingPartnerAdminRole()}
    <div class="item">
      <div class="itemTh">宅配事業者管理者専用<br />メモ欄</div>
      <div class="itemTd">
        <div class="itemTdLeft column">
          <textarea
            id="shippingPartnerInternalMessageChange"
            wrap="soft"
            bind:value={changedShippingPartnerInternalMessage}
            on:input={handleInputChange}
            style="width: 476px; height: 100px; padding: 4px;"
            maxlength="20000"
          />
          <p class="note">
            ※宅配事業者の管理者専用の内部メモ (管理業務の状況整理用)
          </p>
          <p class="note">
            ※「日付＋状況毎に固定の文言」の形式でご入力いただくと、検索結果一覧でフィルター・ソートを使用して状況の把握がしやすくなります。
            <span
              style="display: inline-block; margin-top: 3px; margin-left: 1em;"
            >
              例）<span class="highlight">0701管理者確認</span>、<span
                class="highlight">0702住所不明:待機</span
              >、<span class="highlight">0709住所不明:返品</span>
            </span>
          </p>
        </div>
      </div>
    </div>
  {/if}
</div>

<style lang="scss">
  .item {
    display: flex;
    gap: 10px;
    position: relative;
    padding: 6px 0;
    border-bottom: 1px solid #eee;
  }
  .itemTh {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    padding: 10px 0;
    min-width: 120px;
    line-height: 1.2em;
    background-color: #b4d0f1cb;
    color: #242424;
    font-size: smaller;
    font-weight: 900;
    text-align: center;
  }
  .itemTd {
    display: flex;
    flex-grow: 1;
    align-items: center;
    gap: 10px;
  }
  .column {
    flex-direction: column;
  }
  .itemTdLeft {
    width: 400px;
    flex-grow: 1;
    display: flex;
    color: #333;
    font-size: 13px;
    .note {
      margin-top: 6px;
      padding-left: 1em;
      text-indent: -1em;
      line-height: 1.2em;
      font-size: 11px;
      color: #666;
      .highlight {
        background-color: #e6e6e6;
        border-radius: 4px;
        margin-top: 3px;
        padding: 3px;
      }
    }
    :global(.mdc-select__selected-text),
    :global(.mdc-deprecated-list-item) {
      font-size: 14px;
    }
  }
</style>
